@import '~dyl-components/config.scss';

.RecordLocations__location {
    color: $primary-table-7;
    border: 1px solid $primary-table-7;
    background-color: $secondary-6-b;
    border-radius: 0.5em;

    &:focus,
    &:hover {
      color: $primary-table-7;
      background-color: $primary-table-8;
    }

    padding: 0.5em;
}

.ui.label.DataTab__label {
  background-color: $primary-table-5;
  border: 1px solid $secondary-7;
  margin: 0px 3px 5px 3px;
}

.ui.segment.CustomDataBox {
  background-color: #f4f6ff;
 
  &--isEditing {
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
  }

  &--onColumnEdit {
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
      border: 1px solid #2979ff;
  }
  padding-bottom: 1em;

  .CustomDataBox__form {
      padding-top: 1.5em;
  }
}
