.ui.form.Template__variables {
    .button {
        padding: 0.5em;
    }

    .fields {
        margin-bottom: 0.5em;
    }
}

.Template__attachments-section {
    min-height: 62.5px;
}

.Template__attachments {
    margin-top: 0.5em;
}

.Template__body-character-count {
    text-align: right;
}

.ui.modal > .content.Modal__content .ui.form textarea.Template__body {
    border: none;
    min-height: 250px;
}
