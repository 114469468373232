@import "dyl-components/config.scss";

.ui.grid.LinkedContacts {
    .icon.LinkedContacts__add-button {
        margin-top: -0.4em;
    }
}

.LinkedContacts__search-bar {
    &--no-text {
        .ui.input > input {
            width: 17.5em;
        }
    }
}
