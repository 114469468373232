.ui.segment.grid.TextsTimeline {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.ui.header.TextsTab__Header {
  .icon.TimelineTab__add {
    margin-top: -0.5em;
  }
}

.TextsTab__NewText {
  padding-top: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.ui.checkbox.TextsTab__otherControls {
  padding-top: 4px;

  label::before {
    background: #f0f0f3;
    border-radius: 25%;
    border: 1px solid #f0f0f3;
  }

  label::after {
    background: #57dcdc;
    border-radius: 25%;
    border: 1px solid #57dcdc;
  }

  input:checked ~ label:after {
    color: white;
  }
}

.TextsTab__Timeline {
  padding-bottom: 40px;
  padding-top: 20px;
  padding-right: 12px;
  padding-left: 26px;

  .TextsTab__Info {
    overflow: hidden;

    .Info__name {
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      float: left;
      font-size: 16px;
      color: #1c1d21;
      font-family: "Lato - Black";
      font-weight: 700;
      padding-top: 5px;

      &--selected {
        color: #8181a5;
      }
    }

    .Info__description {
      clear: both;
      width: 70%;

      &--selected {
        color: #8181a5;
      }
    }

    .Info__status {
      float: right;
      margin-right: 20px;
      font-size: 14px;
      padding: 5px 15px;
      border-radius: 5px 5px;

      &--received {
        color: #2979ff !important;
        background-color: #f2fdf7 !important;
      }

      &--upcoming {
        color: #2979aa !important;
        background-color: #f5f5fb !important;
      }

      &--sent {
        color: #3cd5d5;
        background-color: #f5f5fb;
      }
    }
  }
}

.TextsTab__Menu {
  border-bottom: 1px solid #e9e9f0;
  padding-top: 0px;
  padding-bottom: 7px;

  .ui.pointing.secondary.menu {
    border-bottom: none;

    .item {
      padding-left: 19px;
      padding-right: 2px;
      padding-bottom: 8px;
    }

    .active.item {
      color: #3b86ff;
    }

    a.item:active {
      color: #3b86ff;
      border-color: #3b86ff;
    }
  }
}

.ui.header.TextsForm__Header {
  color: #2979ff;
  font-family: "Lato - Bold";
  font-size: 20px;
  font-weight: 700;
}

.ui.grid.TextFormBody {
  padding-left: 15px;
  padding-right: 15px;

  .row.TextFormBody__Row {
    padding-left: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;

    .TextFormBody__textArea {
      height: 100%;
    }

    .TextFormBody__rescheduleButton {
      position: absolute;
      bottom: 50px;
      left: 7px;
    }

  }
}

.TextFormFooter {
  padding-bottom: 20px;
  padding-top: 20px;
}
