i.link.icon.EventItem__edit { 
    color: #2979ff; 
    font-size: 10px;
    float: right;
}

.EventItem__name {
    display: inline-block;
    width: 75px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    color: #1c1d21; 
    font-family: "Lato"; 
    font-size: 14px; 
    font-weight: 700; 
    font-style: normal; 
    letter-spacing: normal; 
    line-height: 21px; 
    text-align: left;
    vertical-align: middle;
}

.EventItem__note {
    color: #444444; 
    font-family: "Lato"; 
    font-size: 12px; 
    font-weight: 400; 
    font-style: normal; 
    letter-spacing: normal; 
    line-height: 18px; 
    text-align: left;
}

.Timeline__group {
    padding-bottom: 2em;
}

.Timeline__items {
    &:not(:last-child) {
        padding-bottom: 2em;
    }
}
