@import "dyl-components/config.scss";

.Contact {
    display: flex;
    vertical-align: middle;

    &__information {
        display: flex;
        padding-left: 1em;
        padding-top: 2px;
        line-height: normal;
        width: 85%;
        align-self: center;
    }

    &__link {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        padding-left: 0;
        max-width: 19em;
    }

    &__actions {
        margin-left: 1em;
        margin-top: 7px;
        .icon {
            margin-right: 15px;
            color: $primary-table-6;
        }
    }
}
