.ui.form .fields {
    .field.ItemsPerPage {
        padding-left: 12em;
        .field.ItemsPerPage__input  .ui.input {
            width: 85%;
        }
    }
}

.field.ActionButtons {
    position: relative;
    top: 1.7em;
   

    .inline.fields .field {
        .ui.button {
            padding-left: 3em;
            padding-right: 3em;
        }
    }
}
