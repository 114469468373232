@import "~dyl-components/config.scss";

.ui.grid.ModulesTab {
    .ui.segments.RecordsTable {
        border: none;

        .ModulesListTable {
            &__SmallFixedWidth {
                min-width: 120px;
            }

            &__MediumFixedWidth {
                min-width: 170px;
            }

            &__LargeFixedWidth {
                min-width: 300px;
            }
        }
    }

    .ui.table {
        margin-bottom: 0;
    }

    .ModulesTab__segment.ui.segment {
        border-top: 10px solid $primary-table-6 !important;
    }

    .ModulesTab__menu.ui.menu {
        background-color: transparent;
        border-color: none;
    }

    .ModuleRow__outcome {
        &--lost {
            color: rgba($color: $primary-error-2, $alpha: 0.5);
        }

        &--won {
            color: rgba($color: $primary-success-1, $alpha: 0.5);
        }
    }
}
