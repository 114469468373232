.ui.modal.ConfirmModal {
    border-top: none 0px;
    border-radius: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 270px;
    z-index: 2000;

    div.content {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .ConfirmModal__Selection {
            display: flex;
            justify-content: center;

            i.icon {
                margin-left: 25px;
                margin-right: 25px;
                font-size: 21px;
                cursor: pointer;
            }

            i.icon.times {
                color: red;
            }

            i.icon.check {
                color: green;
            }
        }
    }
}
