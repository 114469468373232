@import '~dyl-components/config.scss';

.ImportSteps {
    &__step {
        color: $greys-granny-smith;

        &--active {
            color: black;
            .divider.ImportSteps__divider {
                color: black;
            }
        }
    }
}

.section.ImportSteps__step {
    &:not(:first-child) {
        padding-left: 30px;
    }
}
