.ui.grid {
    .row.ActiveCallActionsContainerGrid {
        padding-top: 0.5em;
    }
}

.ActiveCallActionsContainerDiv {
    text-align: right;
}

.ui.button.ActiveCallActionsContainerButton {
    margin-top: 0.5em;
}
