@import '~dyl-components/config.scss';


.ui.segment.Assignment__Rules__controls {
  border-top-width: 7px;
  border-top-style: solid;
  border-top-color: $primary1-governor-bay;
  padding-left: 0;
  padding-right: 0;
  max-height: calc(100vh - 17em);
  white-space: nowrap;

  .ui.menu.Assignment__Rules__controls-menu {
    justify-content: center;
  }
}

.ui.segment.Assignment__Rules__rightPanel {
  max-height: calc(100vh - 17em);
  white-space: nowrap;
  overflow-y: auto;
  padding-left: 50px;
}

.Assignment__Rules-options {
  max-height: calc(100vh - 40em);
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: -webkit-center;

  .Assignment__Rules-option {
    margin: 1em 1.5em;
  }

  .ui.segment.Assignment__Rules-category {
    margin: 1em 1.5em;
  }
}

.Assignment__Rules-search {
  margin: 1em 1.5em;
  margin-right: 2em;
}

.ui.menu.Assignment__Rules-categories {
  margin-left: 1.5em;
  margin-right: 2em;
  width: unset !important;

  .item {
    justify-content: center;

    &.active {
      background-color: $primary;
      color: $white !important;

      &:hover {
        background-color: darken($color: $primary, $amount: 20) !important;
        color: $white !important;
      }
    }

    &:hover {
      background-color: unset;
      color: $primary !important;
    }
  }
}

.ui.segment.Assignment__Rules__Container {
    background-color: $secondary-6-b;
    border-left: 0.5em solid $primary-table-8;
    
    &--active {
        border-left: 0.5em solid $primary-table-6;
    }
}

.field.AssignmentRules__operatorField {
  flex: 2;
}

.AssignmentCondition__container {
  display: flex;
  margin-bottom: 30px;

  &--last {
    display: flex;
  }
}

.AssignmentCondition__numberedContainer {
  display: flex; 
  flex: 1;
  justify-content: center;
}

.AssignmentCondition__numberContainer {
  background-color: black;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AssignmentCondition__number {
  color: $grey-8
}

.AssignmentCondition__conditionContainer {
  flex: 14;
  margin-left: 10px;
  
}

.AssignmentRules__stateField > div > .MultipleToggableDropdown,
.AssignmentCriteria__sourceField > div > .NestedDropdown{
    background-color: $grey-8;
}

.AssignmentRules__settingsLeft {
  background-color: $primary-table-7;
  padding: 20px;
  display: flex;
  border-radius: 15px;
  white-space: initial;
}

i.icon.AssignmentRules__settingsIcon {
  color: $grey-8;
}

.AssignmentRules__settingsIcon {
  color: $grey-8;
}

h4.ui.header.AssignmentRules__settingsIcon {
  color: $grey-8;
  margin-bottom: 10px;
  margin-top: 1px;
}

.ui.grid>.column:not(.row).AssignmentRules__ruleColumn, .ui.grid>.row>.column.AssignmentRules__ruleColumn {
  padding-left: 0;
}

i.big.big.big.icon.AssignmentRules__dndIcon, i.big.big.big.icons.AssignmentRules__dndIcon {
  position: absolute;
  margin-top: 7px;
  left: 0px;
  color: $grey-3;
}

.ui.form .field:last-child.CompositeCondition__input {
  flex: 1;
  margin: 0 0 1.2em;
}

.ui.form .field.CompositeCondition__select {
  flex: 1
}

.ui.form .field.CompositeCondition__input {
  flex: 1;
}

.ui.form .field:last-child.CompositeCondition__select {
  margin-bottom: 1em;
}

.InnerRuleContainer__Wrapper {
  .ui.ui.multiple.dropdown.MultipleNestedDropdown {
    background-color: $grey-7;
    height: 38.625px;
    padding: 0;

    .ui.label {
      margin-right: 5px;

      .icon {
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }
}

.AssignmentRules__rangeContainer {
  display: flex;
  gap: 25px;
  align-items: center;

  .field:last-child.AssignmentRules__minRange {
    flex: 1;
    margin-bottom: 1em;
  }

  .field.AssignmentRules__maxRange {
    flex: 1;
  }
}
