@import "dyl-components/config.scss";

.CampaignContactsList {
    div.ui.header.CampaignContactsList__PageHeader {
        margin-top: 0.8em;
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .ContactsToolbar {
                &__NewContactsButton.ui.button {
                    margin-left: auto;
                    color: $white;
                    background-color: $primary-table-6;
                }
            }
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.table {
                    margin-bottom: 0px;
                }
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .icon.fas.fa-copy {
        color: $primary-table-6;
    }

    .CampaignContactsTable {
        &__SmallFixedWidth {
            min-width: 120px;
        }

        &__MediumFixedWidth {
            min-width: 220px;
        }

        &__LargeFixedWidth {
            min-width: 300px;
        }
    }

}

.ContactStatus {
    &__active {
        color: $primary-success-1;
    }

    &__inactive {
        color: $primary-error-1;
    }
}
