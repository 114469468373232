.OrdersTable {
    .Person {
        max-width: 200px;
    }

    &__SmallFixedWidth {
        min-width: 120px;
      }

    &__MediumFixedWidth {
        min-width: 170px;
    }

    &__LargeFixedWidth {
        min-width: 300px;
    }

    &__OrderInvoicesTable {
        overflow-y: auto;
        height: 250px;
        white-space: nowrap;

        .OrderInvoicesTable__InvoiceIDColumn {
            min-width: 10vw;
        }
    }
}
