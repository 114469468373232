.ui.form .fields.ImportForm {
    margin-bottom: 1em ;

  .field.ImportForm__CheckBoxDropDown > .ui.selection.dropdown {
    position: relative;
    top: 1.7em;
    margin-bottom: 1em;
  }

  .field.ImportForm__FilterLabel > label {
    position: relative;
    top: 3em;
    margin-left: 0em;
  }
}

.ImportFormDiv {
  padding-top: 30px;
  padding-bottom: 60px;
}
