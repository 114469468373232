@import "dyl-components/config.scss";

.GroupContacts {
    .ui.segments {
        border: unset;
        box-shadow: none;
    }

    .ui.segment.TableWithHeader__header {
        background: unset;
        padding-left: 0;
        padding-right: 0;
    }

    .ui.segments>.segment {
        border-top: unset;
    }

    &__toolbar {
        display: flex;

        .ui.input {
            height: 100%;
        }

        .ui.input > input {
            width: 450px;
        }
        .ui.primary.button {
            margin-right: 15px;
        }
    }

    &__title {
        flex: 1;
    }

    .RecordsTable div.RecordsTable__TableSegment div.TableSegment__OverflowContainer {
        overflow: visible
    }
}

.ui.table .Table__header {
    &--primary tr th {
        background-color: $primary-table-6;
        color: white;
    }
}

.GroupContacts__toolbar {
    .SearchBar__SearchInput {
        width: unset;
    }
}
