@import "dyl-components/config.scss";

.ImportListPanel {
  .ui.segments.TableWithHeader {
    border-style: none;
    box-shadow: none;

    .ui.segment.TableWithHeader__header {
      background-color: $white;
      border-radius: 0px;
      padding: 0px 0px 20px;

      .ImportToolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .SearchBar__SearchInput div input {
          width: 420px;
        }

        &__AddImportButton {
          width: 220px;
          margin-right: 0px;
          color: $white;
        }
      }
    }
  }

  .ui.segment.TableWithHeader__table {
    border-style: none;
    border-radius: 0px;

    .ui.segments {
      border-style: none;
      border-radius: 0px;
      box-shadow: none;

      .ui.segment {
        border-style: none;
        border-radius: 0px;

        .ui.table {
          margin-bottom: 0px;
        }
      }

      .ui.segment.right {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}

.ui.button.primary.AddImportButton {
    background: $primary-table-6;
  }

.ButtonLink.ImportItem__previewLink {
  padding: 0;
  text-align: left;
}
