.CustomDataForm {
    &--archived {
        opacity: 0.5;
    }

    .field {
        label {
            line-height: unset !important;
        }
    }
}
