@import "dyl-components/config.scss";

.QuotesAndOrdersPage {
    div.ui.header.QuotesAndOrdersPage__PageHeader {
        margin-top: 0.8em;
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .QuotesAndOrdersToolbar {
                margin-right: 2em;

                &__QuotesAndOrdersButton.ui.button {
                    color: $white;
                    background-color: $primary-table-6;
                }
            }
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.table {
                    margin-bottom: 0px;
                }
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .QuotesAndOrdersTable {
        &__SmallFixedWidth {
            min-width: 120px;
        }

        &__MediumFixedWidth {
            min-width: 170px;
        }

        &__LargeFixedWidth {
            min-width: 300px;
        }
    }
}
