@import 'dyl-components/config.scss';

.ActiveViewType {
    color: $primary-table-7;
}

.LeftPanel__Container {
    margin-top: 60px; //Adjust this to client-side header
    padding-top: 18px;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $grey-8;
    box-shadow: 3px 0 5px -2px $grey-3;
    max-width: 330px;
    padding-bottom: 370px;

    .LeftPanel__MiniContainer {
        margin-top: 10px;
        margin-bottom: 25px;
    }
}

.CalendarView__Container {
    margin-left: 320px;
    padding: 18px;
}

.Calendar__right {
    overflow-y: auto;
}
