.ButtonLink.event.FeedItem {
    &--active {
        background: #f5f6fa;
    }
}

.ui.feed > .event.FeedItem {
    padding: 1em;
}

.ui.feed>.event>.content {
    width: 100%;
}

.ui.small.feed > .event > .content .summary > .date.FeedItem__meta {
    float: right !important;
}    

.ui.feed > .event > .content .summary > .date.FeedItem__time {
    white-space: nowrap;
    color: #4e4e4e;
    font-family: "Lato";
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}

.ui.feed > .event > .content .summary > .user.FeedItem__date {
    color: black;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}

.Status {
    &--received {
        color: #00bfa5;
    }

    &--sent {
        color: #2979ff;
    }
}

.FeedItem__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
