@import "dyl-components/config.scss";

.RingGroup_Modal {
    .displayOnOffClass {
        top: 2em;
    }
    .Modal__content .callQueue .extension-container {
        flex-direction: column !important;
        width: 50%;
        margin: 0px;
    }
    .callQueue .no_answer_destinations_nested_dropdown {
        max-width: 200px;
    }
    .select-control-segment {
        max-height: 20em;
        overflow: auto;
    }
}
