.ProfileStages {
    &__PipelineName {
        width: 65%;
    }

    &__timestamps {
        font-size: 9px;
        padding-top: 0.3em;
        display: flex;
        justify-content: space-between;
    }
}
