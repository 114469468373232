.ui.grid.Department__header {
    margin: 0;
    color: white;
    background: #47629d;
}

.ui.grid.Department__extensions {
    margin-top: 0;
    margin-bottom: 0;
}

.DepartmentHeading5 {
    padding-left: 20px;
}

.DepartmentButtonLink {
    color: white;
}

.DepartmentDiv {
    padding-left: 30px;
}
