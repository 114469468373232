@import "~dyl-components/config.scss";

.LocationsView {
    &__Header.header {
        padding-left: 25px;
    }

    &__Locations {
        overflow-y: scroll;
        max-height: 370px;
        padding: 0px 25px 0px 25px;

        .Location {
            margin-bottom: 20px;

            &__address {
                div {
                    margin-top: 5px;
                    cursor: pointer;
                }
            }

            &__checkbox {
                margin-top: 5px;
            }
        }
    }

    &__Buttons.ui.segment {
        padding-top: 10px;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__Buttons--close.ui.segment {
        background: rgb(244, 245, 247);
        .ui.form div:first-child {
            margin-bottom: 12px;
        }
    }

    &__AddLocationButton {
        padding-left: 0;
    }

    &__AddLocationSection {
        padding: 20px;
        background: rgb(244, 245, 247);
    }
}

.ButtonLink.LocationsView__ButtonLinks {
    outline: none;
    padding-top: 2px;
    padding-left: 13px;

    span {
        font-size: 10px;
    }

    &:hover {
        color: $primary-table-6;
    }
}

.LocationsView__Buttons {
    .LocationsView__AddButton {
        margin-left: 0;
    }
}
