@import "dyl-components/config.scss";

div.CampaignsPage {
    div.ui.header.CampaignsPage__PageHeader {
        margin-top: 0.8em;
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.table {
                    margin-bottom: 0px;
                }
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .CampaignsTable {
            &__Row--disabled {
                color: rgba(0, 0, 0, 0.4);

                a {
                    color: rgba($primary, 0.4);
                }

                div.ClippedContent {
                    button div.grid div.column {
                        color: rgba($primary, 0.2);

                        i.icon.fa-copy {
                            color: rgba($primary, 0.2);
                        }
                    }
                }

                div.Person {
                    div.sb-avatar {
                        div.sb-avatar__text {
                            color: rgba(0, 0, 0, 0.2) !important;
                            background: rgba(0, 0, 0, 0.05) !important;
                        }
                    }
                }
            }

            &__SmallFixedWidth {
                min-width: 120px;
            }

            &__MediumFixedWidth {
                min-width: 170px;
            }

            &__CampaignConversionColumn {
                min-width: 200px;
            }

            &__LargeFixedWidth {
                min-width: 300px;
            }

            .StatusColumn {
                font-weight: bold;

                &--active {
                    color: $secondary-4;
                }

                &--expired {
                    color: $primary-error-1;
                }
            }

            &__campaign-name {
                word-break: break-word;
            }
        }
    }
}
