.icon.CustomFieldModal {
    &__infoMessage {
        float: right;
        margin-right: 1em;
    }
}

.ui.modal.CustomFieldModal {
    .content.Modal__content {
        padding-right: 4em;

        .FieldTypeDropdown {
            &__icon {
                margin-right: 1rem;
            }
        }
    }
}
