@import '~dyl-components/config.scss';

.ui.secondary.pointing.menu.DataManagementNavBar {
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    top: -1em;
    position: relative;
    margin-top: -1em;
    margin-left: -1em;
    width: 105%;
    background-color: white;
    padding: 0 1.2em;
  
    .item {
      font-size: 12px;
      font-weight: 600;
      color: #777777;

      &:hover {
        color: #2979ff;
        background:rgba(132, 171, 245, 0.25);
        border-radius: 5px;
      }
    }
  
    .active.item {
      color: $primary;
    }
  }
