@import 'dyl-components/config.scss';

.DestinationGroupModal__form {
    .DestinationGroupModal__controllerContainer {
        display: flex;

        .field {
            flex: 0.25
        }
    }

    .DestinationGroupModal__destinationsContainer {
        margin-top: 25px;

        .DestinationGroup__destinationContainer {
            display: flex;
            width: 75%;
            gap: 20px;
            left: auto !important; //These are for the strange offset dnd adds when dragging
            top: auto !important;

            .DestinationGroup___elementContainer {
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                .DestinationGroup__invisibleIcon {
                    visibility: hidden;
                }
            }
    
            .DestinationGroups__destinationField {
                flex: 1;
                max-width: 39.4%;
            }
        }

        .DestinationGroupModal__blackIcon {
            color: $grey-1;
        }

        .DestinationGroupModal__redIcon {
            color: $primary-error-2;
        }
    }
}