@import "~dyl-components/config.scss";

.AccountPage {
    .ProfileHeader {
        &__PipelineConversion {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__ChangePipelineForm {
        width: 20em;
    }

    &__OrderSection {
        .SectionHeader {
            display: inline-block;
        }

        .HeaderOrderID {
            max-width: 75px;
        }

        .CurrentInvoiceColumn {
            display: inline-block;
            width: 100%;

            .CurrentInvoiceValue {
                max-width: 75px;
            }
        }
    }

    .AccountDetails {
        &__header {
            small {
                margin-left: 1em;
            }
        }
    }

    &__ChangePipelineForm {
        .AssociateContactDropdown {
            max-width: 280px;
        }
    }
}

.ui.button.Button {
    &--inactive {
        background-color: white;
        color: $primary-table-6;
        border: 1px solid $grey-5;

        &:hover {
            @include darken-on-hover(background-color, $secondary-6-b);
        }
    }

    &--active {
        background-color: $secondary-6-b;
        color: $primary-table-6;
        border: 1px solid $secondary-6;

        &:hover {
            @include darken-on-hover(background-color, $secondary-6);
        }
    }

}
