@import "~dyl-components/config.scss";

div.ConversionControls {
    button.ui.ConversionControls__StatusDropdown {
        width: 11em;
        color: white;

        i.icon {
            float: right;
        }

        &--convert {
            background: #2635DE;

            &:hover {
                background: darken(#2635DE, 10%);
                color: white;
            }
        }

        &--active {
            background: $primary-success-2;

            &:hover {
                background: darken($primary-success-2, 10%);
                color: white;
            }
        }

        &--paused {
            background: $secondary-2;

            &:hover {
                background: darken($secondary-2, 10%);
                color: white;
            }
        }

        &--canceled {
            background: $primary-error-1;

            &:hover {
                background: darken($primary-error-1, 10%);
                color: white;
            }
        }

        &__StatusDropdown {
            i.icon {
                float: right;
            }
        }
    }
}
