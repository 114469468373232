@import "~dyl-components/config.scss";

.ui.header.TasksTab__Header {
  .icon.TimelineTab__add {
    margin-top: -0.5em;
  }
}

.ui.header.TasksForm__Header {
  color: $primary-table-7;
  font-family: "Lato - Bold";
  font-size: 20px;
  font-weight: 700;
}

.ui.grid.TaskFormBody {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  .row.TaskFormBody__Row {
    padding-left: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;

    .TaskFormBody__taskType {
      margin-left: 10px;

      .ButtonLink {
        position: absolute;
      }
    }

    .TaskFormBody__rescheduleButton {
      position: absolute;
      bottom: 50px;
      left: 7px;
    }

    .TaskFormBody__textArea {
      height: 100%;
    }
  }
}

.TaskFormFooter {
  padding-bottom: 20px;
  padding-top: 20px;
}

.item.Task__description .message_toolbar {
  max-width: max-content;
}
