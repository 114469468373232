
.ui.segment.grid.ActivitiesTimeline {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.ui.header.ActivitiesTab__Header {
  .icon.TimelineTab__add {
      margin-top: -0.5em;
  }
}

.ui.header.ActivitiesTab__Header {
  font-family: "Lato - Bold";
  font-size: 22px;
  font-weight: 700;
}

.ActivitiesTab__NewActivity {
  padding-top: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.ui.checkbox.ActivitiesTab__otherControls {
  padding-top: 4px;

  label::before {
    background: #f0f0f3;
    border-radius: 25%;
    border: 1px solid #f0f0f3;
  }

  label::after {
    background: #57dcdc;
    border-radius: 25%;
    border: 1px solid #57dcdc;
  }

  input:checked ~ label:after {
    color: white;
  }
}

.ActivitiesTab__Timeline {
  padding-bottom: 40px;
  padding-top: 20px;
  padding-right: 12px;
  padding-left: 26px;

  .ActivitiesTab__Info {
    overflow: hidden;

    .Info__name {
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      float: left;
      font-size: 16px;
      color: #1c1d21;
      font-family: "Lato - Black";
      font-weight: 700;
      padding-top: 5px;

      &--selected {
        color: #8181a5;
      }
    }

    .Info__description {
      clear: both;
      width: 70%;

      &--selected {
        color: #8181a5;
      }
    }

    .Info__status {
      float: right;
      margin-right: 20px;
      font-size: 14px;
      padding: 5px 15px;
      border-radius: 5px 5px;

      &--missed {
        color: #f84ba2;
        background-color: #fff0fc;
      }

      &--received {
        color: #2979ff !important;
        background-color: #f2fdf7 !important;
      }

      &--upcoming {
        color: #2979aa !important;
        background-color: #f5f5fb !important;
      }

      &--sent {
        color: #3cd5d5;
        background-color: #f5f5fb;
      }

      &--completed {
        color: #3cd5d5 !important;
        background-color: #f2fdf7 !important;
      }
    }
  }
}

.ActivitiesTab__Menu {
  border-bottom: 1px solid #e9e9f0;
  padding-top: 0px;
  padding-bottom: 7px;

  .ui.pointing.secondary.menu {
    border-bottom: none;

    .item {
      padding-left: 19px;
      padding-right: 2px;
      padding-bottom: 8px;
    }

    .active.item {
      color: #3b86ff;
    }

    a.item:active {
      color: #3b86ff;
      border-color: #3b86ff;
    }
  }
}

.ActivityFormFooter {
  padding-bottom: 20px;
  padding-top: 20px;
}
