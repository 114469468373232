@import "~dyl-components/config.scss";

.field.LocationsForm__label .ui.input {
    padding-top: 10px;
}

.LocationsForm__separator {
    margin: 20px 0 20px 0;
    border-top: 2px solid $grey-5;
    border-radius: 1px;
}