@import "~dyl-components/config.scss";

.ui.segment.CustomDataForm__fields {
    border-left: 0.5em solid $primary-table-6;
    padding-top: 1.5em;
    background: $secondary-7-b;
    
    .field label {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 14em;
        overflow-x: hidden;
    }

    .ui.segment.CustomDataForm__fields {
        padding-top: 1.5em;
        background: $primary-table-9;
    }
}

.CustomData--padded {   
    padding-bottom: 1em;

    padding-top: 1.5em;
}
