.AccountOptions {
    .results.visible {
        max-height: 268px;
        overflow-y: scroll;
    }

    &__dropdown {
        &--unclearable .ui.dropdown .dropdown.icon {
            display: none;
        }
    }
}