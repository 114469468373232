@import "~dyl-components/config.scss";

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.lastWirelessCheckDate {
  float: right;
}

.column .FieldValue__value {
  text-align: right;
}

.ui.modal>.Modal__header.header {
  padding-top: 0;
}

.ui.modal.Modal {
  margin-top: 0 !important;
}

.ui.modal {
  margin-top: 0 !important;
}

.ui.segment.TabSegment {
  border-top-width: 7px !important;
  border-top-style: solid !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-top-color: $primary-table-6;
}

//hack need to refactor back to dyl-components

.field {
  position: relative;
}

i.primary.link.icon.fas.fa-circle-caret-down{
  color: $primary-table-6 ;
}
i.primary.link.icon.fas.fa-circle-caret-up{
  color: $primary-table-6;
}