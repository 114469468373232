.ui.form.InfoSection {
    max-height: 65vh;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    .field .field.disabled {
        opacity: .90;

        .ui.dropdown.NestedDropdown {
            opacity: .4;
        } 
    }

    .field .field {
        .ui.dropdown.NestedDropdown {
            padding-left: 15px;
            padding-top: 10px;
        }

        .ui.dropdown.NestedDropdown .icon.times.clear {
            top: 37%;
        }
    }
}
