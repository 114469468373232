@import "~dyl-components/config.scss";

i.link.icon.ProfileFavoriteIcon {
    &--yellow {
        color: $secondary-3;
    }

    &--grey {
        color: $grey-2;
    }
}
