.Activity {
    .ItemCard {
        text-overflow: ellipsis;

        &__title {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__description {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .Timeline__marker-line {
        margin-left: 0;
    }

    .TimelineItem__date {
        white-space: nowrap;
        .TimelineItem__day {
            white-space: nowrap;
            font-size: 10px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
    
        .TimelineItem__day-of-month {
            white-space: nowrap;
            color: #4e4e4e; 
            font-family: "Lato"; 
            font-size: 10px; 
            font-weight: 400; 
            font-style: normal; 
            letter-spacing: normal; 
            line-height: normal;
        }
    
        
    }

    .TimelineItem__time {
        white-space: nowrap;
        color: #4e4e4e; 
        font-family: "Lato"; 
        font-size: 10px; 
        font-weight: 400; 
        font-style: normal; 
        letter-spacing: normal; 
        line-height: normal;
    }
}

.ViewAll {
    cursor: pointer;
    color: #2979ff;
    font-size: 10px;
    &:hover {
        color: #2635DE;
    }
}
