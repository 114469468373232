@import '~dyl-components/config.scss';

.ui.form {
    .field.SearchBar input {
        background-color: #f8f8f8;
    }
    .field.SearchBar--valid input {
        color: $primary;
        background-color: #f8f8f8;
    }
    .field.SearchBar--invalid input {
        border-color: $error;
    }
}

