@import "dyl-components/config.scss";

div.ui.popup.ErrorPopup {
    color: $primary-error-1;
    background-color: $primary-error-4;
}

div.ui.popup.ErrorPopup::before {
    color: $primary-error-1;
    background-color: $primary-error-4;
}
