.ui.form .fields.PlusAddList {
  margin-left: 0em;

  .ui.button.dropdown.icon.PlusAddList__dropdownbutton > .text {
    padding-right: 1em;
  }
  .ui.selection.dropdown.PlusAddList__dropdown {
    position: relative;
    top: -0.3em;
  }
}

.ImportActionsAndMappingDiv {
  padding-bottom: 60px;
  padding-top: 30px;
}
