@import "~dyl-components/config.scss";
$animation-timing: 0.1s;

.ui.dropdown.AddContactOptions {
  .ui.header.AddContactOptions__header {
    margin: 0;
    margin-bottom: -1em;
  }
}
.ui.menu.DYL2MenuTop {
  background-image: linear-gradient(90deg, $primary-table-7 0%, $primary-table-6 100%);
  margin: 0;
  z-index: 10;
  i.chevron.left.circular.link.icon{
    background-color: $white;
    color: $primary-table-7;
  }
  i.chevron.right.circular.link.icon{
    background-color: $white;
    color: $primary-table-7;
  }

  .menu.right.DYL2MenuTopRight {
    &--squished {
      margin-right: 13.8em;
      transition: margin-right $animation-timing;
    }
    &--full {
      margin-right: 4em;
      transition: margin-right $animation-timing;
    }
  }

  .item.DYL2MenuItemTopRight {
    color: $white;
  }

  &--full {
    margin-left: 4em;
    transition: margin-left $animation-timing;
  }

  &--squished {
    margin-left: 12em;
    transition: margin-left $animation-timing;
  }

  .ui.dropdown>.dropdown.icon:before  {
    color: $white;
  }
}

.ui.search.TopNavigation__search > .results {
  overflow-y: auto;
  max-height: 550px;

  a {
    color: $black;
    display: block;
  }
}

.ui.popup.TopNavigation__Notifications {
    z-index: 10 !important;
}

.TopNavigation__Notifications--Dot{
    border-radius: 50%;
    background-color: $primary-error-2;
    border: 1px solid $grey-8;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 5px;
    left: 11px;
}