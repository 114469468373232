.WebformTheme {
  &__colorTiles {
    margin-top: 30px;
    .SelectedElement {
      margin-right: 30px;

      &--selected {
        margin-right: 5px;
      }
    }

    .ui.segment.ColorPicker {
      top: -27px;
    }
  }

  &__customColorTile {
    margin-top: 20px;
  }

  &__logo {
    .ui.header {
      margin-bottom: 30px;
    }

    img.ui.image.WebformTheme__image {
      object-fit: contain;
      cursor: pointer;
      height: 50px;
      width: 150px;

      &--company {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .SelectedElement__border {
      padding-top: 10px;
      padding-bottom: 10px;

      &--hovered {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  .row {
    margin-bottom: 20px;

    .ui.header .sub.header {
      margin-top: 10px;
    }
  }
}
