div.MasterAccountPage {
    div.ClippedContentBreadCrumb__text {
        width: 300px; 
        display: inline-flex; 
    }

    .fas.thumbtack {
        transform: rotateY(0deg) rotate(-45deg);
    }
}
