@import "~dyl-components/config.scss";

.ui.grid .DynamicFields {
  margin-bottom: 60px;
  &__fieldRow.row {
    padding-bottom: 5px;
  }

  &__fieldRow.row:not(:first-child) {
    padding-top: 0;
  }

  &__addButton {
    color: $greys-sirocco !important;
    cursor: pointer;
    width: fit-content !important;
  }

  .Field {
    &__deleteButton {
      position: absolute;
      top: 36px;
      right: -15px;
    }
  }
}
