@import "dyl-components/config.scss";

.Extensions_Container{
.Extensions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; /* Align items vertically centered */
    .SearchBar__SearchInput > div > input {
        width: 450px;
    }
}

.RecordsTable div.RecordsTable__TableSegment div.TableSegment__OverflowContainer{
    overflow: visible;
}

.ui.segments.RecordsTable__TableSegment {
    border-style: none;
    box-shadow: none;
}
.ui.right.aligned.segment{
    border-style: none;
    box-shadow: none;
    position: relative;
}

.Extensions__ButtonGroup {
    display: flex;
    flex-direction: row;
    gap: 10px; /* Adds spacing between buttons */
}

.Extensions__NewUserButton {
    width: 180px;
    margin-right: 0px;
    color: white;
    background-color: $primary-table-6;
}

.voiceboxPin-info-icon {
    cursor: pointer;
}

.voicemailBoxHeader {
    display: flex;
}

.extensionCompanyTable .ui.segments{
border: none;
box-shadow: none;
}
}