@import "dyl-components/config.scss";

.Label {
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
    }
    &__text {
        font-family: "Lato - Black";
    }
    
    &__mandatory {
        color: $primary-error-2;
    }

    &__icon {
        color: $primary-table-7;
    }
}

.ui.table.VariantsTable {
    margin-bottom: 0px;
  
    thead tr.Table__row th {
      background-color: $primary1-blue-periwinkle;
      color: $white;
    }
  }
  