@import 'dyl-components/config.scss';

.CardData {
    box-shadow: 0px 1px 6px 0px $disabled-grey !important;
    border-radius: 2px !important;
    color: $black;
    display: flex;
    flex-direction: row !important;
    height: 40px;
    padding: 10px;
    justify-content: space-around;
    width: 350px !important;
}

.CardDataItem {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    margin: 0 10px;
}