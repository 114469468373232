.MessageContent{
    border-left: 1px solid gainsboro;

    .MessageContent__header{
        border-bottom: 1px solid gainsboro;
    }

    .MessageContent__body{
        height: 500px;
        background-color: white;
        border-bottom: 1px solid gainsboro;
    }

    .MessageContent__consent-text-messaging{
        margin-left: 2rem;
    }

    .MessageContent__send-message{
        margin-left: 2rem;
    }
}
