@import "dyl-components/config.scss";

.MyCalendar {
    display: flex;
    margin-bottom: 25px;

    &__Container {
        flex: 1;
        display: flex;
    }

    &__Integration {
        flex: 1;
    }
}

.ui.header.LeftPanel__Header {
    margin: 0px;
}

.LeftPanel__Container {
    .checkbox label::after  {
        background-color: $grey-1;
    }
}

.BlueIcon {
    color: $primary-table-7;
}

.ui.dropdown.primary.AddActivityButton {
    background: $primary-table-6;
}

.LeftPanel__MiniContainer {
    border-bottom: 1px solid $grey-4;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
}

.LeftPanel__BottomContainer {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 15px;
    overflow-y: auto;
    height: 100%;
}