@import '~dyl-components/config.scss';

$popup-border-radius: 0.285714rem;

div.popup.IntegrationPopup {
    padding: 0px;

    div.IntegrationForm {
        width: 500px;

        &__header {
            display: flex;
            flex-direction: row-reverse;

            background-color: $primary1-blue-periwinkle;
            padding: 5px 15px;
            border-top-left-radius: $popup-border-radius;
            border-top-right-radius: $popup-border-radius;
        }

        &__body {
            padding: 15px 20px 20px;
        }
        .ui.radio.checkbox input:focus~label:after {
            background: $primary1-blue-ribbon;
        }
    }
}
