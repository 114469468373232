.ui.dropdown.ql-custom-dropdown:not(.button) > .default.text {
    color: #444;
}

.ql-custom-dropdown {
    .icon {
        font-size: 12px;
    }
}

.Signature {
    &__template-selector {
        float: left;
        margin-top: 0.1em;   
    }
}
