@import "~dyl-components/config.scss";

.ui.grid.TimelineItemActions > .TimelineItemActions__row > .column {
    padding-left: 0px;
    padding-right: 0px;
}

.ui.grid.TimelineItemActions
    > .TimelineItemActions__row.TimelineItemActions__row--bottom-padded {
    padding-bottom: 4px;
}

.ui.grid.TimelineItemActions
    > .TimelineItemActions__row.TimelineItemActions__row--top-padded {
    padding-top: 4px;
}

.ui.popup.AddForm {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
}

.ui.grid > .row > .column.ActivityFormBody__RightCol {
    padding-left: 5px;

    &--minDuration {
        padding-left: 5px;
        margin-top: 24px;
    }
}

.ui.grid > .row > .column.ActivityFormBody__LeftCol {
    padding-right: 5px;
}

.ui.segment.ActivityFormContainer {
    border-top: 8px solid $primary-table-6;
}

.ui.header.ActivityForm__Header {
    color: $primary-table-7;
    font-family: "Lato - Bold";
    font-size: 17px;
    font-weight: 700;
    margin-top: 0px !important;
}

.ActivityFormBody__Accent {
    color:$primary-error-2;
}

.ActivityFormBody__Buttons {
    display: flex;
    flex: 1;
}

.ui.grid.ActivityFormBody {
    padding-left: 0px;
    padding-right: 0px;

    .header.ActivityFormBody__Header {
        margin-bottom: 5px;
        font-family: "Lato - Bold";
        font-size: 15px;
    }

    .button.ActivityFormBody__SaveButton {
        background-color: $primary-table-6;
        border: 2px solid $primary-table-6;
        color: $primary-button-4;
        flex: 1;
    }

    .button.ActivityFormBody__SaveButton:hover {
        background-color: darken($color: $primary-table-6, $amount: 5%);
    }

    .button.ActivityFormBody__SaveButton:active {
        background-color: darken($color: $primary-table-6, $amount: 10%);
    }

    .button.ActivityFormBody__CancelButton {
        border: 2px solid $primary-error-2;
        background-color: transparent;
        margin-right: 10px;
        outline: none;
        color: $primary-error-2;
        flex: 1;
    }

    .button.ActivityFormBody__CancelButton:hover {
        border-color: darken($color: $primary-error-2, $amount: 5%);
        color: darken($color: $primary-error-2, $amount: 5%);
    }

    .button.ActivityFormBody__CancelButton:active {
        border-color: darken($color: $primary-error-2, $amount: 10%);
        color: darken($color: $primary-error-2, $amount: 10%);
    }

    .row.ActivityFormBody__Row {
        padding-bottom: 0;
    }

    .row.ActivityFormBody__RowHeader {
        border-bottom: 1px solid $grey-4;
        padding-bottom: 9px;
    }

    .row.ActivityFormBody__TextAreaRow {
        padding-left: 0px !important;
        padding-bottom: 5px !important;
        padding-top: 10px !important;

        .ActivityFormBody__rescheduleButton {
        position: absolute;
        bottom: 50px;
        left: 7px;
        }

        .ActivityFormBody__textArea {
        resize: none;
        }

        .ActivityFormBody__textAreaWrapper {
        height: 82%;
        }
    }
}

.ui.form .disabled.field.ActivityForm__typeField {
    opacity: 1;
}
