@import "dyl-components/config.scss";

.Integration {
  display: inline-flex;
  vertical-align: middle;

  div.Integration__Avatar {
    display: flex;
    align-items: center;

    img,
    i.icon {
      min-width: 40px;
      font-size: 2.3em;
      color: $table-toolbar-color;
    }
  }

  &__warning {
    &--not-deleted {
      margin-right: 28px;
    }

    i {
      position: relative;
      top: 9px;
      right: 12px;
    }
  }

  &__info {
    display: inline-grid !important;
    margin-left: 20px;
  }
}
