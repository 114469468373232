@import "dyl-components/config.scss";

.ProductCatalogTab {
    &__ToolbarColumn {
        padding: 0 !important;
    }

    &__Table {
        margin-bottom: 0px !important;

        td.NoProductsDisplay {
            padding: 0 !important;
        }

        td.DisabledProduct {
            color: $grey-3;
        }

        tr.Table__row.CollapsibleTableRow__subrowContent > td:first-of-type {
            border-left: unset !important;
        }
    
        tr.Table__row.CollapsibleTableRow__subrowContent > td {
            background: unset !important;
        }
    }
}
