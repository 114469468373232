@import "~dyl-components/config.scss";

.EmailForm__container {
    padding-left: 2rem;
    padding-right: 2rem;
}

.ui.header.EmailsTab__Header {
    .icon.TimelineTab__add {
        margin-top: -0.5em;
    }
}

#dateHeader {
    margin: 0;
}

.ui.grid.EmailTab,
.TimelineTab__body {
    .EmailsTab__NewEmail {
        padding-top: 5px;
        padding-bottom: 15px;
        padding-left: 15px;
    }

    .column.EmailTab__MessageFeed {
        padding-right: 0;
        padding-top: 27px;
        margin-right: 0;

        .CloseSidepanelButton {
            padding-right: 5px;
        }

        .MessageFeed {
            padding: 0;
        }
    }

    .column.EmailTab__EmailForm {
        padding-left: 0;
        margin-left: 0;

        .EmailThread {
            border-radius: 5px;
            padding-bottom: 20px;
            margin-bottom: 20px;

            .header {
                margin-top: 10px;
                margin-left: 10px;
            }

            &__headerContainer {
                padding-left: 15px;
            }

            &__Divider {
                margin-bottom: 15px 0;

                &--collapsed {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(34, 36, 38, .15);
                    height: 10px;
                    padding-left: 15px;
                }

                &--circle {
                    border: 1px solid rgba(34, 36, 38, .15);
                    width: 30px;
                    border-radius: 50%;
                    padding: 4px;
                    height: 30px;
                    text-align: center;
                    margin: 0;
                    background: $white;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &__NoBorder {
                border: none;
                box-shadow: none;
            }

            &__Item {
                padding: 0 30px;
                width: 100%;

                span {
                    font-weight: 600;
                    font-size: 1.07142857rem;
                }

                &__avatar {
                    display: flex;
                }

                &__email {
                    display: flex;
                    flex-direction: column;
                    align-self: center;

                    &--to {
                        color: $disabled-grey;
                        font-weight: 500 !important;
                        font-size: 12px !important;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &__content {
                    &--trimmed {
                        margin-bottom: 15px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &--expanded {
                        white-space: pre-line;
                        margin-bottom: 15px;
                    }
                }

                &__contentfile {
                    border: 1px solid $disabled-grey;
                    color: $primary;
                    border-radius: 10px;
                    width: fit-content;
                    display: block;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &__adresses {
                    display: flex;
                    flex-direction: column;
                    min-width: 0;
                    overflow: hidden;
                }

                &--long {
                    flex: 1;
                }

                &--date h6 {
                    text-align: end;
                    font-weight: 500;
                    color: $grey-3;
                }

                .meta {
                    text-align: end;

                    i {
                        margin: 0;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    padding-bottom: 10px;
                }
            }

            &__Icons {
                margin-top: 10px;
                text-align: end;
                color: $disabled-grey;

                &--print {
                    &:hover {
                        cursor: pointer;
                        color: $greys-sirocco;
                    }
                }

                &--arrows {
                    &:hover {
                        cursor: pointer;
                        color: $greys-sirocco;
                    }
                }

                &--disabled {
                    color: $disabled-grey;
                }
            }

            &__Row--input {
                width: 50%;

                &::before {
                    width: 0;
                }

                .content {
                    width: 100%;
                }

                .field {
                    width: 100%;
                }
            }

            &__SimpleRow--input {
                width: 100%;

                &::before {
                    width: 0;
                }

                .RichTextEditor {
                    border: 1px solid $greys-sirocco;
                }

                .Template__attachments {
                    &-section {
                        width: 100%;
                    }

                    padding-bottom: 8px;
                    ;
                }

                .content {
                    width: 100%;
                }

                .DragAndDropZone {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .FileHeader {
                        margin: 0;
                    }

                    .FileRemoveButton {
                        top: -3.5em;
                    }
                }
            }

            &__ButtonsRow {
                padding-left: 10px;
                padding-bottom: 20px;
            }
        }

        .TextHistoryButton {
            padding-bottom: 1em;
        }
    }

    .column.EmailTab__FormButton {
        margin-top: 1em;
    }
}

// This is a hack until we figure out whether to add the transition ( expand/contract email ) to the Timeline Component in dyl-components
.IntegratedEmails .ui.grid.segment {

    .TimelineTab__Row.TimelineTab_Body {}

    &.FormTab:last-child {

        .row.FormTab__Row {
            margin: inherit 0;
            padding: 0;

            .EmailTab__TaskForm {
                margin-top: 3rem;
            }

            .EmailTab__LogForm {
                margin-top: 20px;
                margin-bottom: 40px;
            }

        }

        .EmailTab__EmailForm {
            padding: 0;
            margin: 0;

            .EmailThread {
                padding: 0;
                margin: 0;
                border-radius: 0;
                margin-top: -35px;

                div.error.field.EmailThread__CC,
                div.error.field.EmailThread__BCC {
                    div.ui.multiple.selection.dropdown>.label:not(.red) {
                        background-color: #e8e8e8;
                        color: rgba(0, 0, 0, .6);
                    }

                    div.ui.multiple.selection.dropdown>.label.red {
                        background-color: #eacbcb;
                        color: #9f3a38;
                    }
                }

                // RichTextEditor Error Fix
                div.EmailBody {
                    .error.field.EmailBody__RichTexEditor {
                        div.label {
                            top: -10px;
                        }

                        div.RichTextEditor {
                            border-color: #e0b4b4;

                            div.body_toolbar div[role=listbox].ui.dropdown {
                                background-color: white;

                                div.text,
                                i.icon {
                                    color: black;
                                    background-color: white;
                                }

                                div.menu {
                                    border-color: #ccc;

                                    >div[role=option].item:hover div[role=listbox].dropdown {
                                        background-color: #f2f2f2;

                                        div.text,
                                        i.icon {
                                            background-color: #f2f2f2 !important;
                                        }
                                    }
                                }
                            }

                            div.ql-container {
                                color: #9f3a38;
                                background-color: #fff6f6;
                            }
                        }
                    }
                }
            }

            .EmailBody,
            .Email_Attachment {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}

.ui.header.EmailThread__Header {
    color: aqua;
}

.ui.grid.Integration {
    padding-left: 15px;
    padding-right: 15px;

    .row.IntegrationHeader__Row {
        padding-left: 10px;
        padding-top: 30px;
    }

    .row.IntegrationBody__Row {
        padding-left: 10px;
        padding-bottom: 45px;
        padding-top: 5px;
    }
}
