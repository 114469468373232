.OnlyViewPermissions {
    .ui.form{
        .field.disabled>label,
        .disabled.field,
        .disabled.fields > .field,
        .field :disabled,
        .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
                opacity: .95;
            
        }
    }

}

.ui.grid.PermissionProfile__container {
    margin-bottom: 80px;
}