@import '~dyl-components/config.scss';

.ui.button.ActiveCallControls__vm-drop {
    background-color: #5485AE;
    color: white;
}

.ui.button.active.ActiveCallControls__vm-drop {
    background-color: darken(#5485AE, 15%);
    color: white;
}
