.CallboxContentDiv {
    padding-left: 30px;
    padding-top: 30px;
}

.CallboxDialDiv {
    padding-left: 30px;
    padding-bottom: 15px;
    padding-top: 30px;
    padding-right: 30px;
}
