.DevicesModal__form {
    .DevicesModal__controllerContainer {
        display: flex;
        gap: 20px;

        .field {
            flex: 1
        }

        .disabled.field {
            opacity: unset;

            label {
                opacity: unset;
            }

            .ui.disabled.dropdown {
                opacity: unset;
            }
        }
    }
}