$color-item-active: #008df3;

.MessageList{

    .MessageList__items{
        height: 520px;
        overflow-y: scroll;
        padding-top: 1rem;

        .MessageList__item{

            &--active{
                background-color: $color-item-active;
                color: white;
            }
        }
    }
}
