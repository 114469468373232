.TransferDestination {    
    

    &__icon {
        padding-top: 25px;
        padding-bottom: 15px;
    }
    &__name {
        padding-bottom: 25px;
    }
    &--dragging-over {
        box-shadow: 0 0 5px #2979ff !important;
        border: 1px solid #2979ff !important;
    }

    
}

.ui.segment.TransferDestination {
    margin-bottom: 20px;
}

.ActiveCallers {
    &--compact {
        max-height: 150px;
        overflow-y: scroll;
        overflow-x: auto;
    }
}
