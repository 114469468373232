@import 'dyl-components/config.scss';

.CallRoutingRuleModal__form {
    .CallRoutingRuleModal__controllerContainer {
        display: flex;
        gap: 25px;
        width: 75%;

        .field {
            flex: 1
        }
    }

    .CallRoutingRules__ruleField {
        max-width: 31%;
    }

    .CallRoutingRuleModal__rulesContainer {
        margin-top: 25px;

        .CallRoutingRule__ruleContainer {
            display: flex;
            width: 100%;
            gap: 20px;
            align-items: center;
            left: auto !important; //These are for the strange offset dnd adds when dragging
            top: auto !important;

            .CallRoutingRule___elementContainer {
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                .CallRoutingRule__invisibleIcon {
                    visibility: hidden;
                }
            }

            .CallRoutingRules__timesContainer {
                flex: 1;
                max-width: 42%; 
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .CallRoutingRules__timeControllersContainer{
                    flex: 1;
                    width: 100%;
                    gap: 20px;
                    display: flex;

                    .CallRoutingRules__timeField {
                        flex: 1;
                    }
                }

                .CallRoutingRules__errorLabel {
                    white-space: normal;
                    background-color: $primary-error-4;
                    color: $primary-error-1;
                    border: $primary-error-1 1px solid;
                    padding: 7px 25px 7px 25px;
                    margin-bottom: 14px;
                    margin-top: 0;
                }
            }
    
            .CallRoutingRules__timeframeField {
                flex: 1;
                max-width: 21%;

                .ui.multiple.dropdown>.label {
                    background-color: $primary-table-9;
                    box-shadow: 0 0 0 1.5px $secondary-7 inset;

                    i.icon.delete:before {
                        color: $primary-button-1;
                    }
                }
            }
    
            .CallRoutingRules__timeframeField {
                margin: 0 0 1em;
            }
        }

        .CallRoutingRuleModal__blackIcon {
            color: $grey-1;
        }

        .CallRoutingRuleModal__redIcon {
            color: $primary-error-2;
        }

        .CallRoutingRuleModal__greyIcon {
            color: $grey-3;
        }
    }
}