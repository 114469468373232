@import "dyl-components/config.scss";

.ui.form.Pipeline__stage {
    &--dragging .fields {
        background-color: white;
        padding-bottom: 25px;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 10px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 10px;
    }
}

.Pipeline__stage-drag-handle {
    align-self: end;
    margin-right: 10px;
    padding-bottom: 23px;
    &--padded {
        margin-right: 5px;
    }
}

.Pipeline__stage-index {
    align-self: end;
    padding-bottom: 8px;
    margin-right: 3px;
    &--padded {
        margin-right: 1px;
    }
    &--disabled {
        color: $grey-4;
    }
}

.icon.Pipeline__stage-remove-icon {
    align-self: end;
    padding-bottom: 27px;
    margin-left: 10px;
    color: $primary-error-2;
}


.icon.Pipeline__stage-undo-icon {
    align-self: end;
    padding-bottom: 27px;
    margin-left: 10px;
    color: $primary-table-7;
}