@import "~dyl-components/config.scss";

.ContactSearchResults {
    &--hidden {
        display: none;
    }

    &__ContactOptionAsNew {
        color: $primary-table-7;
        max-width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        margin-bottom: -0.2em;
    }
}

.ui.form .three.wide.field.ContactOptions__toggler,
.ui.form .three.wide.field.ContactOptions__roleSelect {
    margin-left: 15px;
}
