.ui.grid {
    .column.TransferDestinationColumn {
        padding-right: 15px;
        padding-bottom: 15px;
    }

    .TransferDestinationsContentGrid {
        padding-top: 20px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 10px;
    }
}
