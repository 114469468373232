.ui.grid {
  .column {
    .CallboxHeaderColumn {
      float: left;
    }
  }
}

.CallboxFaxDiv {
    float: right;
    text-align: right;  
}

.ui.header.CallboxHeader {
  padding-top: 20px;
  padding-bottom: 20px;
}
