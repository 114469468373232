@import 'dyl-components/config.scss';

.CompanyExtensionModal__form {
    .CompanyExtensionModal__controllerContainer {
        display: flex;
        gap: 25px;

        .field {
            flex: 1
        }

        .CompanyExtensions__ruleField {
            max-width: 48%;
        }

        .CompanyExtensionsModal__emailField {
            div.ui.multiple.selection.dropdown>.label:not(.CompanyExtension--error) {
                background-color: $grey-5;
                color: $grey-1;
            }

            div.ui.multiple.selection.dropdown>.label.CompanyExtension--error {
                background-color: $secondary-1-b;
                color: $primary-error-1;
            }
        }
    }

    .CompanyExtensionModal__destinationsContainer {
        margin-top: 25px;

        .CompanyExtension__destinationContainer {
            display: flex;
            width: 75%;
            gap: 20px;
            left: auto !important; //These are for the strange offset dnd adds when dragging
            top: auto !important;

            .CompanyExtension___elementContainer {
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                .CompanyExtension__invisibleIcon {
                    visibility: hidden;
                }
            }
        }

        .CompanyExtensionModal__blackIcon {
            color: $grey-1;
        }

        .CompanyExtensionModal__redIcon {
            color: $primary-error-2;
        }
    }
}