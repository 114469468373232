@import "dyl-components/config.scss";

$animation-timing: 0.1s;

.SettingsPage {
  &__Sidebar {
    &::-webkit-scrollbar {
      display: none;
    }

    &.ui.menu {
      background-color: $primary1-governor-bay;
    }

    &--squished.ui.left.sidebar {
      width: 73px;
      transition: width $animation-timing;
      .item:first-child {
        padding: 11px 0px 9px 12px;
      }
    }
    &--full.ui.left.sidebar {
      width: 22em;
      transition: width $animation-timing;

      .item:first-child {
        padding: 11px 0px 9px 12px;
      }
    }

    .item:first-child {
      background-color: $white !important;
      transition: padding $animation-timing;
    }

    .Sidebar {
      &__MenuItem {
        &--full.item {
          padding: 2em 1.5em;
          transition: padding $animation-timing;
        }
        &--squished.item {
          padding-right: 30px !important;
          padding: 30px 5px;
          transition: padding $animation-timing;
        }

        .fas.fa-phone-alt.icon {
          transform: rotate(100deg);
        }

        .ui.image.MenuItem__DYL2Logo {
          cursor: pointer;
        }

        .MenuItem__Container {
          display: flex;
          .MenuItem__Icon {
            margin-top: 1px;
            float: left;

            .icon {
              font-size: 14px;
            }

            .fas.fa-phone-alt.icon {
              transform: rotate(100deg);
            }
          }

          .MenuItem__Name {
            margin-left: 15px;

            .ui.header,
            .ui.header .sub.header {
              color: $white;
            }

            .ui.header .sub.header {
              font-size: 12px;
              margin-top: 5px;
              font-weight: 100;
              opacity: 0.8;
            }
          }
        }
      }

      &__MenuItem.active.item {
        .icon {
          color: $primary1-governor-bay;
        }

        .fas.fa-phone-alt.icon {
          transform: rotate(100deg);
        }

        background-color: rgba(
          red($white),
          green($white),
          blue($white),
          0.915
        ) !important;

        .MenuItem__Container {
          .MenuItem__Icon {
            .icon {
              color: $primary1-governor-bay;
            }
          }

          .MenuItem__Name {
            .ui.header,
            .ui.header .sub.header {
              color: $primary1-governor-bay;
            }
          }
        }
      }
    }
  }

  &__SidebarPusher {
    margin-top: 5em;

    &--full {
      margin-left: 23em !important;
      width: 84%;
      padding-right: 4em;
      transition: margin-left $animation-timing;
    }

    &--squished {
      margin-left: 5em !important;
      width: 96%;
      transition: margin-left $animation-timing;
    }

    .SidebarPusher {
      &__Segment {
        min-height: 89.5vh;
        padding-right: 1em;
        margin-left: 3em;
        

        &--full {
          width: 97%;
          transition: margin-left $animation-timing;
        }
    
        &--squished {
          width: 95%;
          transition: margin-left $animation-timing;
        }


        .Segment {
          &__Header {
            font-family: "Lato - Bold";
            color: $primary1-governor-bay;
            margin-bottom: 2.8em;

            .sub.header {
              color: $primary1-governor-bay;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}

.ui.visible.left.sidebar.SettingsPage__Sidebar~.pusher {
  transform: unset;
}

.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar.SettingsPage__Sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}
