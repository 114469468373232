@import "dyl-components/config.scss";

.ModulesListPage {
    div.ui.header.ModulesListPage__PageHeader {
        margin-top: 0.8em;
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;
        
        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .ModulesListToolbar {
                &__NewModulesListButton.ui.button {
                    margin-left: auto;
                    color: $white;
                    background-color: $primary-table-6;
                }
            }
        }

        .ui.segment.TableWithHeader__table {
            border-style: none;
            border-radius: 0px;

            .ui.segments {
                border-style: none;
                border-radius: 0px;
                box-shadow: none;

                .ui.segment {
                    border-style: none;
                    border-radius: 0px;

                    .ui.table {
                        margin-bottom: 0px;
                    }
                }

                .ui.segment.right {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }

        .ModulesListTable {
            &__ConvertColumn {
                width: 10em;

                i.icon {
                    float: right;
                }
            }

            &__Outcome--won {
                color: $primary-success-1;
            }

            &__Outcome--lost {
                color: $primary-error-2;
            }

            &__SmallFixedWidth {
                min-width: 120px;
            }

            &__MediumFixedWidth {
                min-width: 170px;
            }

            &__LargeFixedWidth {
                min-width: 300px;
            }
        }
    }
}
