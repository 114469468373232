.ui.dropdown.HoverableIconWithList {
    margin-right: 1em;
    cursor: pointer;

    .menu {
        max-height: 13em;

        .StatusIcon {
            width: 25px; 
            display: inline-block;
        }

        .ClippedContent {
            display: inline-block;
            max-width: 150px;
        }

        &>.item:hover {
            background: unset !important;
            cursor: unset;
        }
    }
}
