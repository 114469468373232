.EditField_WarningMessage {
    font-family: 'Lato - Bold Italic';
    color: #000000;
    font-size: 14px;
    line-height: 17px;
    display: block;
    padding-top: 10px;
}

.icon.EditFieldModal {
    &__infoMessage {
        float: right;
        margin-right: 1em;
    }
}

.ui.modal.EditFieldModal {
    .content.Modal__content {
        padding-right: 4em;
    }

    .EditFieldModal__EditableCategorizedOptions {
        div.ui.dropdown.visible.active {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            border-top-right-radius: 0.29rem !important;
            border-top-left-radius: 0.29rem !important;

            div.text {
                margin-left: 1em;
                margin-bottom: 1em;
            }

            div.menu.visible {
                position: relative;
                border-top: none;
                box-shadow: none;
            }
        }
    }
}
