@import "dyl-components/config.scss";

.AccountsPanel {
  .ui.segments.TableWithHeader {
    border-style: none;
    box-shadow: none;

    .ui.segment.TableWithHeader__header {
      background-color: $white;
      border-radius: 0px;
      padding: 0px 0px 20px;

      .AccountsToolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .SearchBar__SearchInput div input {
          width: 420px;
        }

        &__NewAccountsButton {
          width: 220px;
          margin-right: 0px;
          color: $white;
        }
      }
    }
  }

  .ui.segment.TableWithHeader__table {
    border-style: none;
    border-radius: 0px;

    .ui.segments {
      border-style: none;
      border-radius: 0px;
      box-shadow: none;

      .ui.segment {
        border-style: none;
        border-radius: 0px;

        .ui.table {
          margin-bottom: 0px;
        }
      }

      .ui.segment.right {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .ui.button.Accounts__hasAccountsButton {
    color: $primary-table-7;
    background-color: $secondary-6-b;

    &:focus,
    &:hover {
      color: $primary-table-7;
      background-color: $primary-table-8;
    }

    width: 11em;
  }

  .ui.table>tbody>tr>td.Account__status {
    text-align: center;
  }

  .ui.dropdown.Account__status-dropdown {
    i.icon {
      float: right;
    }
  }

  .AccountsTable {
    &__SmallFixedWidth {
      min-width: 120px;
    }

    &__MediumFixedWidth {
      min-width: 170px;
    }

    &__LargeFixedWidth {
      min-width: 340px;
    }
  }
}

.Accounts__info {
  display: flex;
  flex-direction: row;

  .FilterValue {
    &--not-zero {
      color: $primary-table-7;
    }
  }
}

.ui.dropdown.primary.AddAccountButton {
  background: $primary-table-6;
}
