@import "dyl-components/config.scss";

.GroupsPage {
    div.ui.header.GroupsPage__PageHeader {
        margin-top: 0.8em;
    }

    .GroupsToolbar {
        display: flex;

        &__searchContainer {
            flex: 1;

            .ui.input {
                width: 100%;
            }
        }
    }
    
    .ui.fixed.table td, .ui.fixed.table th {
        overflow: visible;
    }

    &__hotlistRow {
        color: $primary-error-2;
    }

    .ui.table tbody tr.Table__row.CollapsibleTableRow__subrowContent > td:first-of-type {
        border-left: unset;
    }

    .ui.table tbody tr.Table__row.CollapsibleTableRow__subrowContent > td {
        background: unset;
    }

    .ui.checkbox label:before {
        border: 1px solid $primary-text-1
    }
}

.ui.form .fields>.field.GroupForm__sharedSelect {
    min-width: 200px;
}

.GroupFrom__saveBtn {
    float: right;
}

.GroupForm__container {
    padding: 20px 25px 40px 25px;

    .GroupForm__formContainer {
        margin-bottom: 30px;

        .ui.form .field.disabled>label,
        .ui.form .disabled.field {
            opacity: unset;
        }
    }

    .GroupForm__xIcon {
        position: absolute;
        top: 10px;
        right: 5px;
    }
}

.GroupForm__moveContainer {
    min-width: 300px;
    padding: 5px 20px 40px 20px;

    .GroupForm__xIcon {
        position: absolute;
        top: 15px;
        right: 5px;
    }
}