.ui.breadcrumb.Breadcrumb {
    color: grey;
    position: relative;
    left: -17em;
}

.ui.breadcrumb {
    .BreadcrumbSection {
        color: black;
    }
}
