@import "dyl-components/config.scss";

.Tabs.ui.blue.secondary.menu {
    a.item {
        &:hover {
            color: $primary !important;
            background: rgba(132, 171, 245, 0.25) !important;
            border-radius: 5px;
        }
        &.active:hover {
            color: white !important;
            background: darken($color: $primary, $amount: 15) !important;
        }
    }
}

.Tabs.Tabs--preferencesTabOpen.ui.secondary.menu {
    .item:nth-child(n + 4):nth-child(-n + 8) {
        padding-left: 40px;
    }
}
