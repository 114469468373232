@import "~dyl-components/config.scss";

.ui.segment.Action {
    background-color: $secondary-6-b;
    border-left: 0.5em solid $primary-table-8;
    
    &--active {
        border-left: 0.5em solid $primary-table-6;
    }
}

.one.wide.column.Action__drag-handle {
    width: 1em !important;
}

