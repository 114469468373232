@import "dyl-components/config.scss";

.ui.table.IntegrationTable {
  margin-bottom: 0px;

  thead tr.Table__row th {
    background-color: $primary1-blue-periwinkle;
    color: $white;
  }

  thead tr {
    th:nth-child(1) {
      padding-left: 60px;
    }
  }
  
  div.IntegrationTable__Avatar {
    display: flex;
    align-items: center;

    img, i.icon {
      min-width: 40px;
      font-size: 2.2em;
      color: $table-toolbar-color;
    }
  }

  .DeletedIntegrationRow {
    &__disconnected {
      color: $primary-error-1;
    }

    .ButtonLink {
      text-decoration: underline;
    }
  }
}
