.EmailSenderForm {
    &__IntegrationDropdown {
        div.ui.dropdown {
            div.divider.text {
                max-width: 95%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        img.ui.image {
            display: inline-block;
            margin-right: 10px;
        }
    }
}
