.CompanyProfile {
  margin-bottom: 40px;
  &__field {
    &--input_padded .ui.input {
      padding-left: 20px;
    }
    &--input_padded .ui.selection.dropdown {
      margin-left: 20px;
      width: 92% !important;
    }
    .ui.label.pointing {
        margin-left: 20px;
    }
  }
}

.ui.basic.segment.CompanyProfile__container {
  padding: 0;
    margin-top: 0;
    margin-bottom: 100px;
}

.CompanyContactInfo {
  margin-bottom: 40px;

  &__field {
    &--input_padded .ui.input {
      padding-left: 20px;
    }
    &--padded.field {
      padding-left: 26px !important;
    }
  }

  &__numbers.ui.grid .row {
    padding-bottom: 0;
    padding-top: 0;
    .column {
      padding-left: 7px;
      padding-right: 6px;
    }
  }

  &__numbers.ui.grid .row &__primaryColumn.column {
    display: flex;

    .Primary__number.field {
      width: 75%;
      .ui.input {
        width: 85%;
      }
    }

    .Primary__extensionLabel {
      position: relative;
      left: -30px;
      padding-top: 25px;
    }

    .Primary__extension.field .ui.input {
      padding-top: 19px;
      
      input {
        width: 100% !important;
      }

      &::before {
        content: "ext";
        position: absolute;
        font-weight: bold;
        left: -22px;
        top: 25px;
      }
    }
  }
}
