@import 'dyl-components/config.scss';

.ui.styled.accordion.AdditionalContactData {
    background-color: $primary-table-9;
    border: none;
    box-shadow: none;

    .title {
        border: none;

        &::after {
            content: "";
            margin-bottom: 1em;
        }
    }
}

.ContactForm {
    &__list-items {
        .ui.grid {
            margin-top: 0;
        }
    }
}