@import "dyl-components/config.scss";

div.CampaignsTab {
    div.ui.header.CampaignsTab__PageHeader {
        margin-top: 0.8em;
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.table {
                    margin-bottom: 0px;
                }
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .CampaignsTable {
            .StatusColumn {
                font-weight: bold;

                &--active {
                    color: $secondary-4;
                }

                &--expired {
                    color: $primary-error-1;
                }
            }

            &__Row--disabled {
                color: rgba(0, 0, 0, 0.2);

                a {
                    opacity: 0.2;
                    color: $primary;
                }

                div.ClippedContent {
                    button div.grid div.column {
                        opacity: 0.2;

                        i.icon.fa-copy {
                            opacity: 0.2;
                        }
                    }
                }

                .StatusColumn {
                    font-weight: bold;

                    &--active {
                        color: rgba($secondary-4, 0.2);
                    }

                    &--expired {
                        color: rgba($primary-error-1, 0.2);
                    }
                }
            }

            &__SmallFixedWidth {
                min-width: 120px;
            }

            &__MediumFixedWidth {
                min-width: 170px;
            }

            &__LargeFixedWidth {
                min-width: 300px;
            }


        }
    }
}
