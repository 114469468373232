.error.field {
    .field.ZipcodesField {
        .ui.multiple.selection.dropdown>.label:not(.red) {
            background-color: #e8e8e8;
            color: rgba(0, 0, 0, .6);
        }
    }
}

.field.ZipcodesField {
    .ui.multiple.selection.dropdown>.label.red {
        background-color: #eacbcb;
        color: #9f3a38;
    }
}
