@import '~dyl-components/config.scss';

.TextMessageModal__contact-number {
    font-weight: bold;
}

.TextMessageModal__remaining-character-count-indicator {
    font-style: italic;
    color: $greys-granny-smith;
}

.TextMessageModal__consent {
    font-size: 0.9rem;
}
