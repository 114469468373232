$margin: 10px;
$animation-timing: 0.1s;

.ui.attached.menu:not(.tabular) {
  border: none;
}

.ui.segment.DYL2Main__content {
  padding-top: 6.25em;
  min-height: 100vh;

  &--full {
    margin-right: 62px - $margin / 2;
    transition: margin-right $animation-timing;
  }

  &--squished {
    margin-right: 160px + $margin;
  }

  &--open-right {
    margin-right: 0;
  }
}

.pusher.SidebarPusher {
  width: 100%;

  &--open {
    left: -100px + $margin;
    transition: left $animation-timing;
  }

  &--open-with-open-right-sidebar {
    margin-left: 165px + $margin /2;
    transition: right $animation-timing;
  }

  &--closed {
    left: -198px - $margin / 2;
    transition: left $animation-timing;
  }

  &--closed-with-open-right-sidebar {
    margin-left: 52px + $margin /2;
    margin-right: 0;
    transition: right $animation-timing;
  }
}

.ui.sidebar.SideBar2 {
  background: white;
  z-index: 10;
  &--open {
    left: 160px + $margin;
  }

  &--closed {
    left: 60px - $margin / 4;
  }

  .ui.grey.mini.circular.icon.inverted.button.SideBar2__closeButton {
    color: grey;
    margin: 1em 1em 0 1em;
  }
}

.Main__action-bar {
  margin-top: -2em;
  padding-bottom: 0;

  &--active {
    padding-bottom: 8em;
    transition: padding-bottom 0.1s;
  }
}
