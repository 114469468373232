div.HoverListComponent {
    &__Trigger {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 250px;
    }

    &__Container {
        overflow-y: auto;
        max-height: 150px;
        width: 200px;
    }
}
