@import '~dyl-components/config.scss';

.ui.form .field.AssignmentRules__activeToggle {
    scale: 0.8;
    right: 10px;
}

.AssignmentRules__reorderIcon {
    color: $grey-3;
}

.ui.table tr.Table__row td.AssignmentRules__numberCell:first-child {
    padding-left: 10px;
}

.ui.table>tbody>tr>td.AssignmentRules__reorderToggleCell {
    padding-left: 0px;
}

.AssignmentRules__removeIcon {
    color: $primary-error-2;
}

.AssignmentRules__undoIcon {
    color: $primary-table-7;
}

.ReorderingInfo {
    float: right;
    padding-right: 1em;

    .ui.label {
        top: -4px;
        border: none;
        padding: .833em 1em;
    }
}