@import "dyl-components/config.scss";

.SettingsPage {
  &__TopNavigation {
    height: 69px;
    .item {
      font-family: "Lato - Bold";
    }

    .item:first-child .icon {
      background-color: rgba(
        red($primary1-governor-bay),
        green($primary1-governor-bay),
        blue($primary1-governor-bay),
        0.5
      );
      box-shadow: none;
      width: 2em !important;
      height: 2em !important;
    }

    .item:first-child .icon::before {
      font-size: 25px;
      position: relative;
      top: -5px;
    }

    .item:first-child .icon.angle.right::before {
      color: $primary1-governor-bay;
      right: -1px;
    }

    .item:first-child .icon.angle.left::before {
      color: $primary1-governor-bay;
      left: -1px;
    }

    .item:not(:first-child) {
      padding: 19px 15px;
      cursor: pointer;
    }

    .item:last-child { 
      margin-right: 15px; 
    }

    .item:not(:first-child) .icon {
      margin-right: 7px !important;
    }

    a.item:hover:not(.active) {
      background-color: $white;
    }

    &--squished {
      .item:first-child {
        padding-left: 24em;
        transition: padding-left 0.1s;
      }
    }

    &--full {
      .item:first-child {
        padding-left: 7em;
        transition: padding-left 0.1s;
      }
    }
  }
}
