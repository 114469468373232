@import '~dyl-components/config.scss';

.ui.segment.DialPad {
    background-color: #e4e8f2;
    &__search-bar {
        background: #f4f3f3;
    }

    &__call-button {
        background: #fcfbfc;
    }

    &--primary {
        background: #303f9f;
        color: white;
    }
}
