@import '~dyl-components/config.scss';

.TableHeader {
    &__search {
        width: 5em;
    }
}

.link.icon.TableHeader__sort {
    &--inactive {
        opacity: 0.25;
    }
}
