@import '~dyl-components/config.scss';

.ui.segment.OfficeView__departments {
    padding: 0;
}

.OfficeView__active-call-controls {
    padding: 1.5em 0;
    text-align: center;
}
