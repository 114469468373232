@import "~dyl-components/config.scss";

.ui.label.ModuleHeader__closed-label {
    background-color: $secondary-7;
    color: $secondary-7-b;
}

.ModuleHeader {
    &__move-module-form {
        max-width: 1024px;
        overflow-x: hidden;
    }

    &__IdSpan {
        color: $black;
        margin-left: 10px
    }

    &__copyIcon {
        margin-left: 10px;
    }
}

.ui.grid {
    .row.ModuleHeader__headerRow {
        padding-left: 15px;
    }

    .column.ModuleHeader__headerColumn {
        padding-left: 10px;
    }
}

.ui.header.ModuleHeader {
    &__nameHeader {
        margin-bottom: 0
    }

    &__leadIdHeader {
        white-space: nowrap;
    }
}

.ui.button.icon.ModuleHeader__rightBtn {
    margin-left: 10px;
}
