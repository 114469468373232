@import "dyl-components/config.scss";

.RingGroupOrCallQueue{

.ui.segments.RecordsTable {
    border-style: none;
    box-shadow: none;
}

.ui.right.aligned.segment{
border-top: none;
}
.ui.segment.RecordsTable__TableSegment {
    border-style: none;
    border-radius: 0px;
}

.RingGroupHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .SearchBar__SearchInput > div > input {
        width: 450px;
    }
}

.RingGroupHeader__ButtonGroup {
    display: flex;
    flex-direction: row;
    gap: 10px; 
}

.RingGroup__NewUserButton {
    width: 180px;
    margin-right: 0px;
    color: $white;
    background-color: $primary-table-6;
}
}