@import "dyl-components/config.scss";

.ui.left.center.very.wide.popup.transition.visible.e911-validations {
    background-color: $primary-error-4;
    color: $primary-error-1;
    border: $primary-error-1 1px solid;
}

.ui.left.center.popup.e911-validations:before{
    background-color: $primary-error-4;
    box-shadow: 1px -1px 0 0 $primary-error-1;
}