.ui.grid {
    .row.TransferFormRow {
        padding-top: 0.5em;
    }
}

.TransferFormDiv {
    text-align: right;
}

.TransferFormButton {
    margin-top: 0.5em;
}
