.Signature {
  &__templateSelector {
    float: left;
    min-width: 150px;
    padding-top: 2px;

    .dropdown.icon {
      float: right;
      padding-top: 4px;
    }
  }
}
