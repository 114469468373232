@import '~dyl-components/config.scss';

.AudioModal__form {
    .AudioModal__controllerContainer {
        display: flex;
        gap: 20px;

        .field {
            flex: 1;
            
        }
    }
}

.AudioModal__controllerContainer--Attachment--required{
    color: $primary-error-1;
}
