@import "dyl-components/config.scss";

.ui.bottom.center.popup.ProductInterestsSection__Content, .ui.top.center.popup.ProductInterestsSection__Content {
    background-color: $secondary-6-b;

    .ui.ui.multiple.dropdown {
        background-color: $grey-8;
        border: 1px solid $grey-1;
    }

    .FormFlex {
        display: flex; 
        padding-bottom: 150px;

        &__Dropdown {
            margin: 0px;
        }
    }
}

.ProductInterestsSection {
    &__Trigger {
        .FlexContainer {
            display: flex;

            &__Header {
                margin: 0; 
                flex: 1; 
            }
        }
    }
}
