@import "dyl-components/config.scss";

.ui.accordion.DataManagementAccordion {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 15px;
  padding-top: 0;
  padding-bottom: 0;

  .content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  &--active {
    padding-bottom: 0;
    background: #EDEDED;
  }

  .active.title {
    color: #2635DE
  }

  .title {
    text-align: left
  }
}

.ui.header.Pipelines__stagesHeader {
  color: $primary;
}

.ui.medium.header.DataManagementActionsHeader {
  float: right;
  margin-right: 6.3em;
}

.ui.medium.header.DataManagement__groups-header {
  color: #000000;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.ui.grid>.row>.column.DataManagement__groups-header-container {
  .ui.button.primary {
    background-color: #2635de;
  }
}

.ui.grid>.row>.column.DataManagement__groups-controls {
  .ui.button.primary {
    background-color: #2635de;
  }
}


.ui.segment.DataManagementSegment {
  height: 60px;
}

.DataManagement__group-name {
  color: #000000;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;

  &:hover {
    color: #2979ff;
  }
}

.DataManagementAccordion {
  .ui.table>tbody>tr>td:last-of-type {
    text-align: right;
    padding-right: 5.3em;
  }
}

.StagePopup {
  &__container {
    min-width: 768px;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
  }

  &__form {
    flex: 1
  }

  &__btnContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }

  &__closeIcon {
    position: absolute;
    top: 7px;
    right: 3px;
  }
}
