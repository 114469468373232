@import "~dyl-components/config.scss";

.Checkout__payment {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    left: 4em;

    iframe {
        position: absolute;
        top: 0;
        left: 3em;
        bottom: 0;
        right: 0;
        width: 80%;
        height: 100%;
    }
}

.ui.segment.PaymentMethod {    
    &--active {
        background-color: rgba($primary-table-6, 0.075);
    }
}
