@import "dyl-components/config.scss";

.AccountOpportunity {
  &__searchBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .SearchBar__SearchInput div input {
      width: 400px;
    }
  }

  &__newButton.ui.button {
    color: $white;
    background-color: $primary1-blue-periwinkle;
  }

  &__tabSegment {
    .header {
      padding-top: 0.5em;
      padding-bottom: 5em;
    }
    .TabSegment__emptyListHeader.header {
      padding-top: 4em;
    }
  }
}
