.Row {
    &__action {
        margin-right: 15px;
    }
}

h3.ui.header.PermissionLink{

    // color: blue;
    cursor: pointer;


}


