@import '~dyl-components/config.scss';

.ui.grid.IntegrationTab {
    .menu .item {
        font-size: 16px;
        font-weight: bolder;
        margin-right: 40px;
        color: $grey-2;
    }
}
