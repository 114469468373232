@import "dyl-components/config.scss";

.DestinationGroups {
    .DestinationGroupsToolbar {
        display: flex;

        &__searchContainer {
            flex: 1;

            .ui.input {
                width: 100%;
            }
        }

        &__button.ui.button {
            margin-left: auto;
            color: $white;
            background-color: $primary-table-6;
        }

        .field.SearchBar__SearchInput.DestinationGroups__search{
            .ui.left.icon.input{
                width: 375px;
            }
        }
    }
    
    .ui.fixed.table td, .ui.fixed.table th {
        overflow: visible;
    }

    .ui.table tbody tr.Table__row.CollapsibleTableRow__subrowContent > td:first-of-type {
        border-left: unset;
    }

    .ui.table tbody tr.Table__row.CollapsibleTableRow__subrowContent > td {
        background: unset;
    }

    .ui.checkbox label:before {
        border: 1px solid $primary-text-1
    }
}