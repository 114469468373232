@import "dyl-components/config.scss";

.pushable:not(body)>.ui.sidebar.RightSidebar,
.pushable:not(body)>.fixed,
.pushable:not(body)>.pusher:after {
  position: fixed;
}

.ui.vertical.menu:not(.icon).RightSidebar {
  width: 62.5%;
}

.RightSidebar {
  overflow-x: hidden;
  overflow-y: hidden;

  &__content {
    padding-top: 3em;
  }

  .HistorySection {
    .AutomationIcon {
      color: $grey-2;
      background-color: $grey-5;
      box-shadow: none;
    }

    &__ActivitySubheader--converted {
      color: $primary-success-1;
    }

    &__ActivitySubheader--created {
      color: $primary-success-1;
    }

    &__ActivitySubheader--removed {
      color: $primary-error-1;
    }

    &__ActivitySubheader--closed {
      color: $primary-error-1;
    }
  }

  &__QuoteSection {
    .OverflowContainer {
      overflow-x: auto;
      max-height: 350px;
      min-height: 250px;

      .QuotesTable {
        &__SmallFixedWidth {
          min-width: 120px;
        }

        &__MediumFixedWidth {
          min-width: 170px;
        }

        &__StatusColumn {
          min-width: 210px;
        }

        &__LargeFixedWidth {
          min-width: 300px;
        }

        .Person {
          max-width: 250px;
        }

        &__QuoteVersionsTableContainer {
          overflow-y: auto;
          max-height: 250px;

          .ClippedContent {
            max-width: 450px;
          }
        }
      }
    }

    .PaginationSegment {
      box-shadow: none !important;
      border: 0 !important;
    }
  }
}
