@import "dyl-components/config.scss";

.ReportsAndLogsPanel {

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .ReportsAndLogsToolbar {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;
  
        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;
  
            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.striped.table { 
                    thead tr.Table__row th {
                        .icon.ReportsAndLogsPanel__sorting {
                            &--down {
                                &::after {
                                    content: '\f176';
                                    opacity: .5;
                                }
                                &::before {
                                    content: '\f175';
                                }
                            }
                            &--up {
                                &::after {
                                    content: '\f175';
                                    opacity: .5;
                                }
                                &::before {
                                    content: '\f176';
                                }
                            }
                        }
                        background-color: $primary1-blue-periwinkle;
                        color: $white;
                        button {
                            padding: 0 5px;
                            margin: 0;
                            background-color: $primary1-blue-periwinkle;
                            i {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
  
            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .Table__row {
            .RowStatus {
                &__Rejected {
                    color: $primary-error-1;
                }
                &__Accepted {
                    color: $primary-success-1;
                }
                &__Partial {
                    color: $primary-success-1;
                }
                &__Duplicate {
                    color: $primary-success-1;
                }
            }
        }

        .Table__row .DataProviderExpand {
            color: $primary1-blue-periwinkle;
            &:hover {
                cursor: pointer;
            }
        }
    }

}

.ui.mini.button.ReportsAndLogsPanel__datemenu--active {
    background-color: $primary-table-6;
    color: $white;
}

.DataDetails {
    white-space: nowrap; 
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.ErrorPopup {
    padding-left: 5px;
    padding-right: 5px;

    .Title {
        display: flex;
        margin-bottom: 5px;

        &__Header {
            flex: 1;
            margin: 0;
        }

        &__Content {
            flex: 3
        }
    }

    .Body {
        display: flex;

        &__Header {
            flex: 1
        }

        &__Content {
            flex: 3
        }
    }
}
