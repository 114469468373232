@import "~dyl-components/config.scss";

.ui.form.list {
  .Task__row {
    padding-left: 2em;
  }

  .field.Task__no-label {
    margin-top: 1.55em;
  }

  .field.Task__no-label--Error-fix {
    margin-top: 0.15em;
    padding-top: 1.4em;
  }

  .Task__checkbox-description {
    padding-left: 4.5em;
  }

  .ql-custom-dropdown {
    .icon {
      font-size: 12px;
    }
  }

  .ui.dropdown.ql-custom-dropdown:not(.button) > .default.text {
    color: #444;
  }

  .fields.Task__form {
    margin-bottom: 0;
  }

  .fields.Task__actions {
    text-align: right;
  }

  .item.Task__guests {
    padding-top: 1em;

    .UserSearchField__user {
      display: flex;
    }

    .UserSearchField__information {
      width: 65%;
    }
  }

  .item.Task__organizer .ui.segment {
    width: 48%;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  div.Task__description div.content {
    max-width: 100px;
  }

  .Task__attachments-section {
    min-height: 125px;
    position: relative;
    top: -8px;
  }

  .Task__attachments {
    margin-top: 0.5em;
    margin-bottom: 1em;

    .DragAndDropZone__header {
      padding-top: 1.5em;
    }

    .header.FileHeader {
      margin: 40px 0 !important;
      display: inline-block !important;
    }
  }
}

.ui.modal > .Modal__content.content.Task {
  padding: 0;
  padding-left: 2em;
}

.ui.grid > .row.Task__times {
  padding-top: 0;
}

.ui.grid > .row.Task__dates {
  padding-bottom: 0;
}

.ui.large.checkbox.Task__no-label {
  padding-top: 1em;
  label {
    padding-left: 2.9em;
  }
}

.ui.checkbox.Task__complete input:checked~.box:before,
.ui.checkbox.Task__complete input:checked~label:before {
    background: $primary-table-7;
}

.ui.checkbox.Task__complete input:checked~.box:after,
.ui.checkbox.Task__complete input:checked~label:after {
    color: $grey-8;
}

.ui.simple.dropdown.Task__template-selector {
  .menu {
    overflow-y: scroll;
    max-height: 250px;
    margin-top: -1.75em !important;

    > .item {
      max-width: 150px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
}

.Task__selected-template {
  max-width: 6em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.ui.grid.TaskFormSearch{
    max-width: 54.65em;
    .SearchBar__SearchInput{
        width: 100%;
    }
    .fields {
        display: unset;
        margin: 0px;
        .field{ 
            padding: 0px;
        }
    }
}
