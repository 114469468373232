.CalendarToolbar {
    display: flex;

    &__LeftContainer {
        flex: 1;
        display: flex;
        gap: 15px;
    }

    &__MonthSelector {
        display: flex;
        align-items: center;
    }

    &__RightContainer {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }
}

.ui.header.CalendarToolbar__Header {
    margin-right: 10px;
    margin-bottom: 0px;
    min-width: 10em;
}
