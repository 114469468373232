@import "~dyl-components/config.scss";

.ContactInfo__title {
    font-family: 'Lato - Regular';
}

.ContactInfo__value {
    font-family: 'Lato - Bold';
    color: #717171;
    text-align: left;
    cursor: pointer;
    word-wrap: break-word;
}

.ContactInfo__value:hover {
    border: 1px solid $primary-table-7;
    padding: 0.25em;
    background-color: $secondary-6-b;
    border-radius: 0.2em;
}

.ui.grid >.ContactInfo__row.row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ui.grid.ContactInfo {
    .row {
        padding-top: 5px;
        padding-bottom: 0;
    }
}

.ContactInfo__contact {
    color: $secondary-6;
    display: inline;

    &--address {
        color: $primary-table-7;
    }
}

.ContactInfo__fieldContainer {
    max-width: 100%
}

i.grey.icon.icon.icon.icon.ContactInfo__locationIcon{
    margin-top: 0px
}

.ui.form .field.ContactInfo__locationField {
    display: flex;
}

.ContactInfo__locationContainer {
    display: flex; 
}

.ContactInfo__locationSpan {
    flex: 4;
}

.ContactInfo__locationFirstRow {
    &--secondRow {
        margin-bottom: 8px;
    }
    &--noSecondRow {
        margin-bottom: 0;
    }
}

.ContactInfo__caretContainer {
    justify-content: flex-end;
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 10px;
}

@media only screen and (max-width: 1120px) {
    .ContactInfo__caretContainer {
        margin-left: 0px;
    }
}

.ContactInfo__caretDown {
    color: $primary-table-7;
}

.ui.label.ContactInfo__locationLabel {
    max-width: 310px;
    color: $primary-table-7;
    border: 1px solid $primary-table-8;
    background-color: $secondary-6-b;
    border-radius: 3px;
    cursor: pointer;

    &--withArrow {
        padding-right: 0;
        padding-left: 12px;
    }

    &--noDefault {
        padding-right: 12px;
        padding-left: 12px;
    }
}
