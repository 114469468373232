@import 'dyl-components/config.scss';

.SequenceSettingsTab {
    &__Header {
        color: $primary !important;
    }

    &__SequenceAccessSelection {
        max-height: 140px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;

        .SelectedSequenceAccess {
            display: inline-block;
            padding: 0.3rem;

            &__Label {
                background-color: $primary-table-9 !important;
                border-color: $primary-table-1 !important;
            }

            i.icon {
                cursor: pointer;
            }
        }
    }

    &__SequenceAccessSearchResults {
        div.ResultsGrid {
            max-height: 250px;
            margin-top: 0px;
            margin-bottom: 0px;
            overflow-y: auto;

            &__HeaderRow {
                color: $grey-2;
                font-weight: bold;
                padding-top: 0.75rem !important;
                padding-bottom: 0.75rem !important;
            }

            &__Row {
                padding-top: 0.5rem !important;
                padding-bottom: 0.5rem !important;
            }
        }
    }

    &__ToggleLabel {
        vertical-align: top;
    }
}
