@import "~dyl-components/config.scss";
//Notes
//EmailThread__Item__contentfile lives here src/shared/EmailTab/index.js under &__contentfile {}

.ButtonLink.event.EmailThread__Item {
    &--active {
        background: #f5f6fa;
    }
}

.ui.feed>.event.EmailThread__Item {
    padding: 1em;
    border-bottom: 1px solid rgba(34,36,38,.15)
}
.ui.feed>.event>.content .summary>.date {
    padding-right: 10px;
}

.ui.feed>.event>.content .summary>.date.EmailThread__Item__meta {
    float: right;
}

.ui.feed>.event>.content .summary>.date.EmailThread__Item__threadCounter {
   
    .label{
        color: $primary;
        border-color: $primary;
    }
}

.EmailThread__Item__content {

    &--trimmed {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }


    &--closed {
        max-width: 64em !important;  
    }

}

.EmailThread__Body{
    font-weight: 100 !important;
    font-size: 12px !important;
}

.EmailThread__Item__email{
    margin-left: .5em;
}
