$body-bg-color: #f6f6f6;

.ui.grid.TextMessageHistory{
    height: 737px;

    .TextMessageHistory__HeaderContainer{
        background: linear-gradient(to right, rgba(125,184,242,0.25) 0%,rgba(125,184,242,0.25) 55%,rgba(138,192,207,0.25) 100%);
        height: 50px;

        .TextMessageHistory__HeaderContainer-Header{
            margin-left: 1rem;
        }
    }
    
    .TextMessageHistory__BodyContainer{
        background-color: $body-bg-color;

        .TextMessageHistory__MessageListContainer{
        }
    }
}
