.AddFeeForm {
    padding-bottom: 15em;

    &__ProductApplication {
        div.menu.transition.visible {
            width: 100% !important;
        }

        div.ToggableDropdown__input {
            border: unset !important;
        }
    }

    &__FeeAmountType {
        label {
            visibility: hidden;
        }
    }
}
