@import "~dyl-components/config.scss";

.ui.header.EventsTab__Header {
  .icon.TimelineTab__add {
    margin-top: -0.5em;
  }
}

.ui.header.EventForm__Header {
  color: $primary-table-7;
  font-family: "Lato - Bold";
  font-size: 20px;
  font-weight: 700;
}

.ui.grid.EventFormBody {
  padding-left: 15px;
  width: 100%;

  .row.EventFormBody__Row {
    padding-left: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;

    .EventFormBody__rescheduleButton {
      position: absolute;
      bottom: 50px;
      left: 7px;
    }

    .EventFormBody__textArea {
      height: 100%;
    }
  }
}

.EventFormFooter {
  padding-bottom: 20px;
  padding-top: 20px;
}
  