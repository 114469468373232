@import "dyl-components/config.scss";

.TableDropdownComponent {
    div.SegmentGroup {
        margin-bottom: 0px;
        border: 1px solid $grey-2;

        div.ui.segment {
            padding: 0px;
        }

        div.SearchBarSegment {
            &__SearchInput {
                width: 100%;

                div.ui.input {
                    input {
                        border: none !important;
                    }
                }
            }
        }
    }
}
