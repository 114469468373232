.ui.form.Template__variables {
    .button {
        padding: 0.5em;
    }

    .fields {
        margin-bottom: 0.5em;
    }
}

.Template__attachments-section {
    min-height: 125px;
}

.Template__attachments {
    margin-top: 0.5em;
}
