.ui.header.ProfileHeader__name {
    margin-left: 12.5px;
}

.ProfileHeader {
    &__avatar {
        &-action-icon.icon {
            top: 0.9em;
            position: absolute;
            display: none;
        }

        &-action-icon-center {
            left: 0.85em;
        }

        &-action-icon-left {
            left: 0.3em;
        }

        &-action-icon-right {
            left: 1.55em;
        }

        &:hover &-action-icon.icon {
            display: block;
            opacity: 1;
        }

        &-overlay {
            height: 5.625em;
            width: 5.625em;
            position: absolute;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            left: 14px;
            right: 0;
            opacity: 0;
            &:hover {
                opacity: 1;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}
