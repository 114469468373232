.CatalogWizard {
    &__categories .ui.secondary.menu {
        .active.item {
            color: white;
            background-color: #2979ff;
        }

        overflow-x: scroll;
        overflow-y: hidden; 
        padding-bottom: 1em;
    }
}
