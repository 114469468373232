@import "dyl-components/config.scss";

.DropdownIcon {
    margin: 0 10px 0 0;
}

.LeadStageRow__NameContainer {
    display: flex;
    gap: 15px;

    .LeadStageRow__NameElement {
        margin: 0;
    }
}

.LeadPipelinesPanel {
    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .LeadPipelineOptions {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: flex-start;
                .SearchBar__SearchInput > div > input {
                    width: 450px;
                }

                &__NewPipelineButton {
                    width: 180px;
                    margin-right: 0px;
                    color: white;
                    background-color: $primary-table-6;
                }
            }
        }

        .ui.segment.TableWithHeader__table {
            border-style: none;
            border-radius: 0px;

            .ui.segments {
                border-style: none;
                border-radius: 0px;
                box-shadow: none;

                .ui.segment {
                    border-style: none;
                    border-radius: 0px;

                    .ui.striped.table {
                        thead tr.Table__row th {
                            padding-top: 20px;
                            padding-bottom: 20px;
                            background-color: $primary-table-6;
                            color: white;
                        }
                    }
                }

                .ui.segment.right {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
    }
}

.vl {
    border-left: 6px solid green;
    height: 100%;
    position: absolute;
    left: 50%;
}

.ui.table.LeadStageTable tr td {
    background-color: cadetblue;
    border-top: 0px !important;
}

.ui.table.LeadStageTable tr {
    background-color: white !important;
}

.LeadStageTable__Footer {
    background-color: $primary-table-4 !important;
}

.PipelineTable {
    &__CollapsibleRow {
        padding-left: 20px;
    }

    &__NameContainer {
        display: flex;
        gap: 20px;
    }

    &__Name {
        margin: 0;
    }

    &__DefaultLink {
        font-size: 12px;
        font-style: italic;
    }
}

.ui.table tr.Table__row td:first-child.PipelineTable__TableCell {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}

.ui.grid > .row {
    .column.LineAssignmentButtonColumn {
        top: 28px;
    }
}

.ui.grid > .row.LineAssignmentFirstRow {
    padding-bottom: 0;
}
.ui.grid > .row.LineAssignmentSecondRow {
    padding-top: 0;
}
.ui.grid > .row.LineAssignmentThirdRow {
    padding-bottom: 0;
}

.ui.input > input {
    width: 15em;
}

.ui.selection.dropdown.LineAssignmentExtension {
    width: 15em;
}

.ui.segment.UserAccountsTable {
    padding-left: 30px;
}

.tempHeader {
    font-weight: bold;
    position: absolute;
    top: 0px;
}

.PipelineTable__DefaultPipeline {
    color: $primary-table-7;
}

.Pipeline__description {
    word-break: break-word;
}
