@import '~dyl-components/config.scss';

.SearchableMultiSelection {
    .SearchableMultiSelection__SearchBar {
        div.results.visible {
            max-height: 268px;
            overflow-y: auto;

            .SearchBar__ResultInfo {
                display: inline-block;
                padding-left: 1em;
                vertical-align: middle;
            }
        }
    }

    .SearchableMultiSelection__Selections {
        box-shadow: none;
        height: 150px;
        overflow-y: auto;

        .Selections__SelectionTag {
            display: inline-block;
            background-color: $primary-table-9;
            border-width: 1px;
            border-style: solid;
            border-radius: .28571429rem;
            border-color: $primary-table-7;
            margin-right: 25px;
            margin-bottom: 10px;
            padding: 3px 3px 3px 6px;

            .SelectionTag__DeleteIcon {
                cursor: pointer;
            }
        }

        .Selections__EmptySelectionPrompt {
            color: $grey-4;
        }
    }
}
