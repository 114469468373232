.ui.segment.CustomData {
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(41, 121, 255, 0.2);
    background-color: #f4f6ff;
}

.ui.grid>.column:not(.row).FieldValue {
    &__custom-field {
        font-size: 14px;
        line-height: 20px;
        font-weight: 1000;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
    }
    &__custom-value {
        font-size: 14px;
        line-height: 20px;
        padding-top: 0;
        padding-bottom: 0.2em;
    }
}
