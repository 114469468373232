.callerID_settings .ui.form .field.AddChannelForm__private-chat > label,
.ui.toggle.checkbox label {
    font-family: "Lato";
}
.expanded-section {
    width: 50%;
    margin: 5px;
}
.add-state-caller-id-btn {
    margin: 1em;
}
.callerID_form {
    margin-bottom: 2em;
}
.callerID-action-icon {
    cursor: pointer;
}
