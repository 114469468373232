@import "~dyl-components/config.scss";

.SubjectDifferentiator {
    color: $primary-success-2;
}

.MinimumErrors{
    color: $primary-error-2;
}

.ui.list.MinimumErrors{
    color: $primary-error-2;
}

.ui.list.MinimumErrors li:before{
    color: $primary-error-2;
}

i.large.icon {
    &.WarningRed {
        color: $primary-error-2;
    }
}