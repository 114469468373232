@import 'dyl-components/config.scss';

.ui.menu .item.ContactSequence__Menu {
    padding: 0;
}

.ui.grid.SequenceTimeline__divider {
    margin-top: 0px;
}

.ui.checkbox.SequenceTime__taskCheckbox label:before {
    border: 1px solid $primary-table-6
}

.TimelineItem__date {
    white-space: nowrap;
    .TimelineItem__day {
        white-space: nowrap;
        margin-right: 4px;
    }

}
