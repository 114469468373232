@import 'dyl-components/config.scss';

.PhoneEmailInfo {
    float: right;
    padding-right: 1em;

    .ui.label {
        top: -4px;
        border: none;
        padding: .833em 1em;
    }
}

.AccountModal__asterisk {
    color: $primary-error-2;
}

.AddAccount__error .content {
    color: $primary-error-2;
}
