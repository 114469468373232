@import "~dyl-components/config.scss";

.LoginWrapper {
    height: 100vh; 
    overflow: hidden;
    background: $grey-6;
}

.ui.segment.Login {
    margin: 0 auto;
    margin-top: 9em;
    margin-bottom: 0;
    max-width: 30rem;
    text-align: center;

    .Login__header {
        padding-top: 0.75rem;
        color: #797979;
        font-weight: 600;
    }
    
    .Login__content {
        padding: 1rem 1rem;
        text-align: left;
    }
    
    .Login__fields {
        padding: 3rem 0;
    }
    
    .Login__footer {
        padding: 1rem 0;
        text-align: left;
    }
}
