@import "dyl-components/config.scss";

.FeesPage {
    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .FeesToolbar {
                &__NewFeesButton.ui.button {
                    color: $white;
                    background-color: $primary-table-6;
                }
            }
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.table {
                    margin-bottom: 0px;
                }
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .FeesTable {
        &__Fee {
            display: flex;
            width: 100%;
            column-gap: 40px;

            .Fee__Name {
                flex: 1;
                display: block;
                width: 25%;
            }

            .Fee__Actions {
                flex: 1;
            }
        }

        &__SmallFixedWidth {
            min-width: 120px;
        }

        &__MediumFixedWidth {
            min-width: 170px;
        }

        &__LargeFixedWidth {
            min-width: 300px;
        }
    }
}
