@import "~dyl-components/config.scss";

.ui.button.CustomerConversion {
    color: white;
    &--convert {
        background: #2635DE;
        
        &:hover {
            background: darken(#2635DE, 10%);
            color: white;
        }
    }
    
    &--active {
        background: $primary-success-2;

        &:hover {
            background: darken($primary-success-2, 10%);
            color: white;
        }
    }
    
    &--paused {
        background: $secondary-2;

        &:hover {
            background: darken($secondary-2, 10%);
            color: white;
        }
    }
    
    &--canceled {
        background: $primary-error-1;

        &:hover {
            background: darken($primary-error-1, 10%);
            color: white;
        }
    }
}

.Account__lost-form {
    width: 15em;
}

.Convert__account-name {
    display: inline-block;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: -0.24em;
}
