.ChatBox {
    .icon.times.link.small {
        padding-right: 2em;
    }
}

.ui.grid>.column.ChatBox {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    padding: 0;
}

.ui.segments.ChatBox__content {
    margin-bottom: 0;
}

.ui.feed > .event > .content .summary > .date.FeedItem__meta {
    float: none !important;
}

.ui.grid>.column.Messages {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
    padding-top: 0;
}
