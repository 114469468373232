form.TerritoryForm {
    padding-bottom: 75px;

    .Teams {
        &__Label {
            display: inline-block;
            margin-bottom: 0px; 
        }

        i {
            vertical-align: super;
            margin-left: 20px !important;
        }
    }
}
