.ui.button.dropdown.CampaignText__Dropdown {
    
    padding-right: 0em;

    .text {
        padding-left: 1em;
    }
    .text + .list.icon {
        position: relative;
        left: -10em;
    }
}
