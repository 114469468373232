.ModulesTable {
    &__OpportunityQuotesTable {
        overflow-y: auto;
        height: 250px;
        white-space: nowrap;

        .Person {
            max-width: 250px;
        }

        .OpportunityQuotesTable__QuoteIDColumn {
            min-width: 10vw;
        }
    }
}
