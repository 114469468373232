@import "dyl-components/config.scss";

.SequenceReportsPage {
    & div.ui.segments.RecordsTable {
        border-style: none;
        border-radius: 0px;
        box-shadow: none;

        .ui.segment {
            border-style: none;
            border-radius: 0px;

            .ui.table {
                margin-bottom: 0px;
            }
        }

        .ui.segment.right {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    &__Table {
        .Table__SmallFixedWidth {
            min-width: 120px;
        }

        .Table__MediumFixedWidth {
            min-width: 170px;
        }

        .Table__LargeFixedWidth {
            min-width: 300px;
        }

        .SequenceReports {
            &__StatusColumn {
                &--completed {
                    color: $primary-success-1;
                }

                &--overdue,
                &--canceled {
                    color: $primary-error-2;
                }
            }

            &__NameColumn {
                max-width: 200px;
            }
        }
    }
}
