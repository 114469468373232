.ui.sidebar.SideBar2 {
    overflow-x: hidden;
    width: 320px;
    z-index: 4;

    &--wide {
        width: 80%;
    }
}

.icon.SideBar2__closeButton {
    margin-left: 1.5em;
    margin-top: 1em;
}

.SidebarContainer {
    margin-left: 2.5em;
    margin-top: 1.5em;
    margin-right: 1.5em;
}

.pushable:not(body) > .ui.sidebar.SideBar2,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}
