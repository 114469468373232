.EmailForwarding{

    a{
        cursor: pointer;
    }

    &--link-white{
        color: white;

        &:hover{
            color: white;
        }
    }

    &--float-right{
        float: right;
    }

    &--indent{
        margin-left: 2rem;
    }
}
