.ui.segment.Permissions {
    &__wrapper {
        padding: 30px 32px;
    }
    &__header {
        margin-bottom: 20px;
    }
    &__table {
        padding: 0;
        table thead tr th {
            &:first-of-type {
                padding-left: 40px;
            } 
            &:last-of-type {
                padding-right: 40px;
            }
        }
        table tbody tr td {
            &:first-of-type {
                padding-left: 40px;
            } 
            &:last-of-type {
                padding-right: 40px;
            }
        }
    }
}

.Pagination {
    &:after {
        content: "";
        clear: both;
        display: table;
    }

    .ui.pagination.menu {
        float: right;
    }
}
