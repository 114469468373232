@import '~dyl-components/config.scss';

.ContactContainer {
    height: 2.5em;
    border: 0.5em solid #d4d4d4;
    border-radius: 0.5em;
    
    &--dragged-over {
        padding: 2.5em;
    }
}
