@import "dyl-components/config.scss";



.Devices {
    
    .RecordsTable div.RecordsTable__TableSegment div.TableSegment__OverflowContainer {
        overflow-x: visible;
    }

    .field.SearchBar__SearchInput.Devices__search{
        .ui.left.icon.input{
            width: 375px;
        }
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;
        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;
            
            .DevicesToolbar {
                &__button.ui.button {
                    margin-left: auto;
                    color: $white;
                    background-color: $primary-table-6;
                }
            }
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.table th.one.wide.Devices__statusHeader {
            width: 6.5%;
        }

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.table {
                    margin-bottom: 0px;

                    i.circle.icon.Devices__statusIcon {
                        color: green;
                        &--red {
                            color: $primary-error-2
                        }
                
                        &--green {
                            color: $primary-success-2
                        }
                    }
                }
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
}