@import "~dyl-components/config.scss";

.ui.form.list {
  .field.Event__timezone {
    margin-left: 2em;
    margin-bottom: 0.5em;

    .divider.text {
      text-decoration: underline;
      color: $primary;
    }
  }

  .field.Event__frequency {
    .divider.text {
      text-decoration: underline;
      color: $primary;
    }
  }

  .Event__contacts {
    border: 1px solid #777777;
    font-size: 0.9rem;

    margin-top: 0.1em;
  }

  .Event__row {
    padding-left: 2em;
  }

  .field.Event__no-label {
    margin-top: 1.55em;
  }

  .field.Event__no-label--Error-fix {
    margin-top: 0.15em;
    padding-top: 1.4em;
  }

  .field.Event__notification {
    padding-bottom: 2em;

    .ButtonLink {
      padding-left: 0;
      padding-top: 0.5em;
    }
  }

  .Frequency__day {
    border-radius: 50em;
    padding: 0.5em 1em;

    &.active {
      background: red !important;
    }
  }

  .ql-custom-dropdown {
    .icon {
      font-size: 12px;
    }
  }

  .ql-formats {
    .table.link.icon {
      margin-top: 0;
    }
  }

  .ui.dropdown.ql-custom-dropdown:not(.button)>.default.text {
    color: #444;
  }

  .Event__template-selector {
    float: left;
    margin-top: 0.2em;
  }

  .fields.Event__form {
    margin-bottom: 0;
  }

  .fields.Event__actions {
    text-align: right;
  }

  .item.Event__guests {
    padding-top: 1em;
    padding-bottom: 2em;

    .UserSearchField__user {
      display: flex;
    }

    .UserSearchField__information {
      width: 65%;
    }
  }

  .item.Event__organizer {
    padding-top: 0.5em;
    padding-bottom: 1.5em;

    .ui.segment {
      width: 48%;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }

  .item.Event__location {
    padding-bottom: 2em;
  }

  div.Event__description {
    padding-bottom: 2em !important;

    div.content {
      max-width: 100px;
    }

    div.error.field div.RichTextEditor {
      div.description_toolbar {
        border-top-color: #e0b4b4;
        border-right-color: #e0b4b4;
        border-left-color: #e0b4b4;

        div[name=template].dropdown {
          background-color: white;

          div.text,
          i.icon {
            color: black;
            background-color: white;
          }

          div.menu {
            border-color: #ccc;

            >div[role=option].item {
              span.text {
                color: black;
                background-color: white;
              }
            }

            >div[role=option].item:hover {
              span.text {
                color: black;
                background-color: #f2f2f2 !important;
              }
            }

            div[role=option].item.selected {
              background-color: #f2f2f2 !important;

              span.text {
                background-color: #f2f2f2 !important;
              }
            }
          }
        }
      }

      div.quill {
        color: #9f3a38;
        background-color: #fff6f6;

        div.ql-container {
          border-color: #e0b4b4;
        }
      }
    }
  }

  .Event__attachments-section {
    min-height: 125px;
    position: relative;
    top: -8px;
  }

  .Event__attachments {
    margin-top: 0.5em;
    margin-bottom: 1em;

    .DragAndDropZone__header {
      padding-top: 1.5em;
    }

    .header.FileHeader {
      margin: 40px 0 !important;
      display: inline-block !important;
    }
  }
}

.ui.modal>.Modal__content.content.Event {
  padding: 0;
  padding-left: 2em;
}

.ui.grid>.row.Event__times {
  padding-top: 0;
  padding-bottom: 1em;
}

.ui.grid>.row.Event__dates {
  padding-bottom: 0;
}

