@import "dyl-components/config.scss";

.Notification_Settings .ui.checkbox input.hidden+label {
    font-family: 'Lato';
    color: $grey-2;
}

.system_notifications_general {
    margin-bottom: 5em;

    .checkbox label:before {
        transform: scale(0.5);
    }
    
    .checkbox label:after {
        transform: scale(0.5);
    }
    
    .ui.checkbox input.hidden+label {
        font-family: 'Lato';
        color: $grey-2;
    }
}

.notification_headers{
    padding-top: 1rem;
}
