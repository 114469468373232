@import "~dyl-components/config.scss";

.column i.inverted.circular.blue.link.icon.ContactGroupForm__checkButton {
    background-color: #2979ff !important;

    &:hover {
        background-color: #2635DE !important;
    }
}

.ContactGroupForm {
    &__buttonContainer {
        display: flex;
        align-items: flex-end;
    }
}

i.icon.redIcon:before {
    color: $primary-error-2;
}
