.EditForwardingNumber{

    a{
        cursor: pointer;
    }
    
    &--float-right{
        float: right;
    }

    &--link-white{
        color: white;

        &:hover{
            color: white;
        }
    }
}
