@import "dyl-components/config.scss";

.NotificationsPage {
    div.ui.header.NotificationsPage__PageHeader {
        margin-top: 0.8em;
    }

    .field.SearchBar__SearchInput.Notification__Search{
        .ui.left.icon.input{
            width: 375px;
        }
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;
        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .NotificationsToolbar {
                &__BulkActionButton.ui.button {
                    margin-left: auto;
                    color: $white;
                    background-color: $primary-table-6;
                }
                &__UserSettingsButton.ui.button {
                    margin-left: auto;
                    color: $primary-table-6;
                    background-color: $white;
                    border: 1px solid $primary-table-6;
                }
            }
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.table {
                    margin-bottom: 0px;
                }
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .icon.fas.fa-copy {
        color: $primary-table-6;
    }

    .NotificationsTable {
        &__convert-dropdown {
            i.icon {
                float: right;
            }
        }

        &__SmallFixedWidth {
            min-width: 120px;
        }

        &__MediumFixedWidth {
            min-width: 170px;
        }

        &__LargeFixedWidth {
            min-width: 300px;
        }
    }

    .Notification__actions {
        .icon {
            margin-right: 15px;
            color: $primary-table-6;
            cursor: pointer;
        }
        .trash{
            color: $primary-error-2;
        }
        .fas.fa-calendar{
            color: $black;
        }
    }
}

