@import 'dyl-components/config.scss';

.AccountComponent {
    display: flex;
    vertical-align: middle;

    &__information {
        display: flex;
        align-items: center;
        padding-left: 1em;
        padding-top: 2px;
        line-height: normal;
        width: 100%;
    }

    &__Link {
        flex: 1;
        display: block;
        max-width: 9.3em;
    }

    &__Account {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        padding-left: 0;
    }

    &__Subtitle {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        font-size: 12px;
    }

    &__Actions {
        flex: 1;

        .icon {
            margin-right: 13px;
            color: $primary-table-6;
        }
    }
    &__MasterAccountActions {
        margin-left: 88px;
        .icon {
            margin-right: 13px;
            color: $primary-table-6;
        }
    }
}
