@import "dyl-components/config.scss";

.DataProvidersPanel {
    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .DataProvidersToolbar {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .SearchBar__SearchInput div input {
                    width: 400px;
                }

                &__NewDataProviderButton {
                    width: 220px;
                    margin-right: 0px;
                    color: $white;
                    background: $primary-table-6;
                }
            }
        }
        
        .Table__row {
            .RowStatus {
                &__Rejected {
                    color: $primary-error-1;
                }
                &__Accepted {
                    color: $primary-success-1;
                }
                &__Partial {
                    color: $primary-success-1;
                }
                &__Duplicate {
                    color: $primary-success-1;
                }
            }
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
}

.DataProviders__info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.DataProviders__editable {
    display: inline-block;
}
