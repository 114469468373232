@import "dyl-components/config.scss";

.CustomerPipeline__timeline {
    .ModuleRow__outcome {
        &--closed {
            color: rgba($color: $primary-error-2, $alpha: 0.5);
        }

        &--open {
            color: rgba($color: $primary-success-1, $alpha: 0.5);
        }
    }

    .PipelineRow__contact {
        width: 30em;
    }

    .ui.dropdown.PipelineRow__contact-dropdown {
        width: 15em;
        display: inline-block;
    }

    .PipelineRow__contact-controls {
        width: 13em;
        display: inline-block;
        margin-top: -2em;
    }

    .HistorySection {
        .AutomationIcon {
            color: $grey-2;
            background-color: $grey-5;
            box-shadow: none;
        }

        &__ActivitySubheader {
            font-weight: 550;
        }

        &__ActivitySubheader--assigned {
            color: $primary-success-1;
        }

        &__ActivitySubheader--created {
            color: $primary-success-1;
        }

        &__ActivitySubheader--stage_changed {
            color: $primary-table-6;
        }

        &__ActivitySubheader--removed {
            color: $primary-error-1;
        }

        &__ActivitySubheader--closed {
            color: $primary-error-1;
        }
    }
}
