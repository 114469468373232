.LeadRouting{
    
    .TableWithHeader{

        &--white-header{
            
            .TableWithHeader__header{
                background-color: white;
                color: black !important;
            }
        }
    }
}

.react-stickies-wrapper .icon:before, .icon:after{
    background-color: transparent;
}
