.ui.segment.MessageEditor {
    background-color: #f8f8f8;
    height: 210px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
    margin-top: 0;

    &--expanded {
        height: 350px;
    }

    .ui.form {
        .MessageEditor__TextArea {
            background-color: '#ffffff';
           
            height: 143px; 
            border-radius: 10px;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        }
    }

   .ui.buttons.MessageEditor__TemplatesAttachments {
            height: 36px; 
            border-radius: 4px;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

            .ui.selection.dropdown {
                padding-left: 55px;
            }

            .ui.button {
                background: white;
                font-size: 13px;
            }

            .ui.button .icon {
                background: white;
                color: black;
            }

            .ui.dropdown.button {
                background: white;
                color: black;

                .dropdown.icon {
                    padding-top: 3px;
                    left: -5px;
                }
            }



          
    }
    
    .ui.button.MessageEditor__Delete {
        border-radius: 4px;
        margin-right: 26px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        background: white;
        color: black;
    }

    .ui.button.MessageEditor__Send {
        font-size: 14px; 

        &~.ui.dropdown.button.icon {
            
        }
    }
    
}



