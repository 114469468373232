@import "dyl-components/config.scss";

.HorizontalFlexList {
    .List {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &__Entry {
            width: 130px;
            margin: 10px 20px 10px 0px;
        }
    }

    .EntryToggleButton {
        button.ButtonLink.ButtonLink--primary {
            display: block;
            margin: 10px auto 0px auto;
        }
    }
}

.ui.header.TeamContainer__header {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.TeamContainer__footer {
    color: $grey-3;
}