.CompanyLogo {
  margin-bottom: 15px;
  
  .CompanyUploadLogo {
    cursor: pointer;
    text-align: center;
    background: rgb(235, 235, 235);
    height: 50px;
    padding-top: 15px;
    width: 150px;
    margin-left: 20px;
  }

  .CompanyCurrentLogo {
    position: relative;
    height: 50px;
    width: 150px;
    margin-left: 20px;

    &:hover .CompanyCurrentLogo__overlay {
      opacity: 1;
    }

    &__logo.ui.image {
      display: block;
      height: 100%;
      object-fit: contain;
      object-position: 0% 50%;
    }

    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: 0.5s ease;
      background-color: rgba(0, 0, 0, 0.8);
      text-align: center;
      padding-top: 15px;

      .icon {
        color: white;
      }

      .icon.camera {
        float: left;
        padding-left: 40px;
      }

      .icon.trash {
        float: right;
        padding-right: 55px;
      }
    }
  }

  .field {
    margin: 0 !important;
  }
}
