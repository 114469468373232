@import "~dyl-components/config.scss";

.ui.grid.form .DataProviderCreate {
  &__step.ui.header {
    margin-bottom: 0 !important;

    span:first-child {
      color: $greys-sirocco;
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }

  &__divider.ui.divider {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }

  &__providerNameType {
    color: $greys-sirocco;
  }

  &__dataProviderNameDropdown {
    &--addedProvider {
      .dropdown .menu .item:last-child {
        display: none;
      }
      .dropdown .menu .item.selected.addition {
        display: block;
      }
    }
  }
}
