.ui.statistic.Metric {
    &>.value.Metric__value {
        margin-bottom: 0.5em;
        text-transform: none;
        
        a {
            color: unset;
        }
    }

    &>.label.Metric__label {
        text-transform: none;
        
        a {
            color: rgba(0,0,0,.87);
        }
    }
}
