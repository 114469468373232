@import '~dyl-components/config.scss';


.ui.segment.Assignment__Rules__controls {
  border-top-width: 7px;
  border-top-style: solid;
  border-top-color: $primary1-governor-bay;
  padding-left: 0;
  padding-right: 0;

  .ui.menu.Assignment__Rules__controls-menu {
    justify-content: center;
  }
}

.Assignment__Rules-options {
  .Assignment__Rules-option {
    margin: 1em 1.5em;
  }

  .ui.segment.Assignment__Rules-category {
    margin: 1em 1.5em;
  }
}

.Assignment__Rules-search {
  margin: 1em 1.5em;
  margin-right: 2em;
}

.ui.menu.Assignment__Rules-categories {
  margin-left: 1.5em;
  margin-right: 2em;
  width: unset !important;

  .item {
    justify-content: center;

    &.active {
      background-color: $primary;
      color: $white !important;

      &:hover {
        background-color: darken($color: $primary, $amount: 20) !important;
        color: $white !important;
      }
    }

    &:hover {
      background-color: unset;
      color: $primary !important;
    }
  }
}

.ui.segment.Assignment__Rules__Container {
    background-color: $secondary-6-b;
    border-left: 0.5em solid $primary-table-8;
    
    &--active {
        border-left: 0.5em solid $primary-table-6;
    }
}

.DistributionUsers__searchContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  .SearchBar__SearchInput>div>input {
      width: 450px;
  }
  &__SearchInput {
      width: 450px;
  }
}

.AssignmentRules_emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.EditCapPopup {
  &__container {
    min-width: 150px;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
  }

  &__form {
    flex: 1
  }

  &__btnContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }

  &__closeIcon {
    position: absolute;
    top: 7px;
    right: 3px;
  }
}

.DistributionUsers__triggerName {
  &--inactive {
    color: $grey-3;
  }
}
