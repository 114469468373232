@import "~dyl-components/config.scss";

.ui.segment.ParentGroup {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}

.field.BlueToggleSwitch{
    display: inline;
    margin-left: 1rem;

    .ui.toggle.checkbox label {
        font-size: 1rem;
        font-weight: 100;
    }
}

.ui.table.CustomFieldsTable {
    margin-bottom: -14px;
    td {
        overflow: unset;
    }
}

.ui.menu.secondary.DataCustomization__groupTypeOptions {
    .item {
        color: $primary-table-6;
    }

    .item.active {
        color: $white;
        background: $primary-table-6;
    }
}
