.rce-container-mbox {
    padding-bottom: 20px;
    padding-top: 10px;

    .rce-mbox {
        width: 185px;
        background-color: #edf0f5;  
        border-radius: 50px 50px 50px 0px;

        .rce-mbox-left-notch {
            display: none;
        }

        .rce-mbox-body .rce-mbox-title.rce-mbox-title--clear {
            position: absolute;
            font-size: 10px;
            top: -26px;
            left: -5px;
            opacity: 0.5;
            color: #43425d;
        }

        .rce-mbox-body .rce-mbox-time.non-copiable {
            position: absolute;
            top: 160%;            
            color: #000000;
            opacity: 0.5;
            font-size: 10px;
            right: auto;
            text-align: left;
            left: -8px;
        }

    }

    .rce-mbox.rce-mbox-right {
        width: 200px;
        background-color: #2877fb;
        color: white;
        border-radius: 20px 20px 0px 20px;

        .rce-mbox-right-notch {
            display: none;
        }

        .rce-mbox-body .rce-mbox-title.rce-mbox-title--clear {
            left: 108px;
        }

        .rce-mbox-body .rce-mbox-time.non-copiable {
            top: 160%;
            right: -8px;
            left: auto;
        }
    }


}

.rce-mbox-body .rce-mbox-text {
    display: grid;
    padding: 2px;
}

.rce-mbox-text:after {
    display: none;
}

.ui.segment.ChatThread {
    height: 312px; 
    overflow: scroll;
    padding-top: 0;
}
