@import "dyl-components/config.scss";

.ParseTemplatePanel {
    margin-bottom: 60px;
    .ui.CardData {
        padding: 20px 0px 20px 0px;
        width: fit-content !important;
        .CardDataItem {
            // margin: 0;
            // margin-bottom: 20px;
            display: flex;
            align-items: center;
            h4 {
                margin: 0 5px;
            }
        }
    }

    .ui.tab {
        max-height: 70vh;
    }

    .ui.button {
        background-color: inherit;
        padding: 0;
        margin: 0 auto;
        color: $grey-2;
        border: none;

        &:hover,
        &:focus {
            background-color: inherit;
        }
    }

    .ui.segment.active {
        overflow: scroll;
        max-height: 70vh;
    }

    .ui.placeholder.segment {
        min-height: fit-content;
        margin-bottom: 60px;
        padding: 50px 30px 30px 30px;
    }

    .ui.segment.ReturnedKeyValuePairsTable {
        width: 100%;
        padding: 30px 0;

        .parse-row {
            background-color: $primary-table-5;

            &:nth-child(even) {
                background-color: $primary-table-4;
            }
        }
    }

    i.icon {
        &:hover {
            cursor: pointer;
        }

        &.NeutralGray {
            color: $grey-3;
        }

        &.ValidBlue {
            color: $primary-button-2;
        }

        &.WarningRed {
            color: $primary-error-2;
        }
    }

    .ui.grid.InputFields {
        margin-top: 5px;
        overflow: hidden auto;
        max-height: 72vh;

        .ui.placeholder:last {
            margin: auto -10px;
        }
    }

    .column.shadow-container:after {
        box-shadow: 0 0 10px rgba(0, 0, 0, 1) inset;
        height: calc(100% - 10px);
        width: 100%;
        left: 0px;
        top: 0px;
        content: "";
        position: absolute;
        pointer-events: none;
    }

    .error {
        color: $primary-error-2;
        padding-top: 5px;
        margin: 5px auto 0px;
        font-weight: bold;
    }

    .bad {
        border: 1px solid $primary-error-2;
        border-radius: 5px;
    }
}

.ParseTemplatePanel__footer.menu {
    background: linear-gradient(rgba($primary1-governor-bay, 0.1), rgba($primary1-governor-bay, 0.1)),
        linear-gradient($white, $white);
    z-index: 100;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 0;
    padding-bottom: 0;

    .ui.basic.button.Button.Button--reset {
        color: $grey-2;
        border-color: $grey-2;
    }
}
