@import "~dyl-components/config.scss";

.ui.search.UserSearchField {
    .ui.input {
        width: 100%;
    }

    .results {
        overflow-y: auto;
        max-height: 275px;
    
        a {
            color: black;
            display: block;
        }
    }

    &--upward {
        .results {
            top: auto;
            bottom: 100%;
        }
    }
}

.ui.segment.UserSearchField {
    margin: 0;
    padding: 0;
    padding-right: 0;
    border: 1px solid #777777;
    box-shadow: none;
    border-radius: .28571429rem;

    .results {
        overflow-y: auto;
        max-height: 275px;
    
        a {
            color: black;
            display: block;
        }
    }

    &--upward {
        .results {
            top: auto;
            bottom: 100%;
        }
    }

    .ui.input {        
        input {
            border: none !important;
        }

        .icon {
            top: 3px;
        }
    }
}

.ui.segment.UserSearchField__user {
    margin-right: 0;
    margin-top: 0.5em;
    margin-left: 0.5em;
    margin-bottom: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    .sb-avatar {
        vertical-align: middle;
    }
}

.UserSearchField {

    &__user {
        display: inline-flex;
        vertical-align: middle;
    }

    &__information {
        display: block;
        padding-left: 1em;
        width: 16em;
        max-width: 250px;
    }

    &__remove-user {
        display: block;
        padding-left: 1em;
        line-height: normal;

        &--expanded {
            padding-left: 0.2em;
        }
    }
}

span.SelectedUser {
    &__name  {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
    }

    &__email {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
    }
}

.UserSearchField__noFontSize {
    .sb-avatar__text {
      div {
        font-size: unset !important;
      }
    }
  }

.UserSearchField__dropdown__close-flex {
    display: flex;
  }
.UserSearchField__ReportsTo_remove-user {
    background: $grey-8;
    z-index: 4;
    height: fit-content;
    position: absolute;
    top: 5px;
    right: 5px;
    display: inline;
    padding-left: 1em;
}
.UserSearchField_ReportsTo_user {
    width: -webkit-fill-available;
}
