.NoAssignedUserTeam { 
    display: inline-block; 
    margin-top: 20px; 
    margin-bottom: 50px; 
    margin-left: 28%;

    i.icon { 
        color: #c4ccdb
    }
}

.UserTeamList {
    padding-bottom: 1em;

    &__LoadTeamMembers {
        padding: 1em 0;

        button {
            display: block;
            margin: 0 auto;
        }
    }
}

span.TeamMember {
    &__name  {
        text-overflow: ellipsis;
        max-width: 110px;
        overflow: hidden;
        display: block;
        white-space: nowrap;
    }

    &__email {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 110px;
        display: inline-block;
        white-space: nowrap;
    }
}

.ui.grid > .three.column > .ui.menu > .item.active {
    color: white;
    background-color: #2a79ff;
}