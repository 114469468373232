.e991-location {
    width: 75%;
    margin-bottom: 4em;
    .Table__toolbar{
        text-align: center;
        padding: 2em;
    }
    .item.e911-item{
        &--disabled{
            pointer-events: initial !important;
        }
    }
}