.ui.popup {
    .ui.form.ActivityForm {
        label {
            font-size: 13px;
        }

        .field .ui.selection.dropdown {
            min-width: 12.3em;
            border-color: #777777 !important;
        }

        .field textarea {
            min-width: 12.3em;
            border-color: #777777 !important;
        }

        .field .ui.input input[type="text"] {
            width: 14.4em;
        }

        .field .ui.input input[type="number"] {
            width: 6.6em;
        }

    }
}
