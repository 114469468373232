.AddonForm {
    padding-bottom: 5em;

    &__ProductApplication {
        div.menu.transition.visible {
            width: 100% !important;
        }

        div.ToggableDropdown__input {
            border: unset !important;
        }
    }
}
