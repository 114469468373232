@import "~dyl-components/config.scss";

.ui.grid.RecordInfo {
    .row {
        padding-top: 0.25em;
        padding-bottom: 0;

        .column:not(:last-of-type) {
            padding-right: 0.25em;
        }

        .column:not(:first-of-type) {
            padding-left: 0.25em;
        }
    }

    padding-bottom: 1em;
}

.ui.grid.RecordInfoAccount {
    margin-top: 0.15em;
    .row {
        padding-top: 0.25em;
        padding-bottom: 0.5em;

        .column:not(:last-of-type) {
            padding-right: 0.25em;
        }

        .column:not(:first-of-type) {
            padding-left: 0.25em;
        }
    }

    padding-bottom: 1em;
}

.ProfileHeader {
    &__container {
        display: flex;
    }

    &__editable {
        cursor: pointer;

        &:hover {
            border: 1px solid $primary-table-7;
            padding: 0.25em;
            background-color: $secondary-6-b;
            border-radius: 0.2em;
        }
    }

    &__nameContainer {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
    }

    &__iconsBox {
        display: inline;
        position: relative;
    }

    &__iconOverlay {
        position: absolute;
        height: 17px;
        width: 23px;
        top: 0;
        right: 0;
        cursor: pointer;
    }

    &__leftContainer {
        margin-bottom: 0.25em;
        display: flex;
    }

    &__iconsContainer {
        margin-left: 5px;
    }

    &__icon {
        margin-bottom: 4px;
    }

    &__avatarContainer {
        padding-left: 0;
        padding-right: 0;
    }

    &__inputsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-bottom: 0.25em;
    }

    &__largeInputContainer {
        flex: 2.5;
    }

    &__inputContainer {
        flex: 1;
    }

    &__btnsContainer {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__businessContainer {
        margin-bottom: 0.25em;
    }
}

.ui.header.ProfileHeader__header {
    margin-bottom: 0.25em;
}

.ui.grid > .column.ProfileHeader__nameColumn {
    padding-left: 0.5em;
}

.field.ProfileHeader__nameInput .ui.input input {
    padding: 5px;
}

i.link.icon.ProfileHeader__icon {
    &--red {
        color: $primary-error-2;
    }
}

i.link.icon.ProfileHeader__checkIcon {
    color: $primary-table-7;
}

i.link.icon.ProfileHeader__xmarkIcon {
    color: $primary-error-2;
}

.Convert__account-name {
    display: inline-block;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: -0.24em;
}

.OpportunityId {
    &__label {
        color: $grey-1
    }
    &__id{
        color: $primary
    }

}
