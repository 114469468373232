@import "dyl-components/config.scss";

.ui.segment.grid.EmailsTimeline {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.ui.header.EmailsTab__Header {
  font-family: "Lato - Bold";
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
}

.EmailsTab__NewEmail {
  padding-top: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.ui.checkbox.EmailsTab__otherControls {
  padding-top: 4px;

  label::before {
    background: #f0f0f3;
    border-radius: 25%;
    border: 1px solid #f0f0f3;
  }

  label::after {
    background: #57dcdc;
    border-radius: 25%;
    border: 1px solid #57dcdc;
  }

  input:checked ~ label:after {
    color: white;
  }
}

.GridTimeline__item:hover {
  border: 1px solid $primary1-governor-bay !important;
  box-shadow: 0 0 9px 1px lightgray;
  cursor: pointer;
}

.EmailsTab__Timeline {
  padding-bottom: 30px;
  padding-top: 50px;
  padding-right: 12px;
  padding-left: 12px;
  
  .ui.grid.EmailsTab__Info {
    overflow: hidden;

    .Info__name {
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      float: left;
      font-size: 16px;
      color: #1c1d21;
      font-family: "Lato - Black";
      font-weight: 700;
      padding-top: 5px;

      &--selected {
        color: #8181a5;
      }
    }

    .Info__description {
      clear: both;
      color: #8181a5;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--selected {
        color: #8181a5;
      }
    }

    .Info__status__EmailTab {
      float: right;
      margin-right: 20px;
      font-size: 14px;
      padding: 5px 15px;
      border-radius: 5px 5px;

      &--received {
        color: $primary1-blue-ribbon;
        background-color: #f5f5fb;
      }

      &--sent {
        color: $primary1-blue-ribbon;
        background-color: #f5f5fb;
      }

      &--done {
        color: #3cd5d5;
        background-color: #f2fdf7;
      }
    }
  }
}

.EmailsTab__Menu {
  border-bottom: 1px solid #e9e9f0;
  padding-top: 0px;
  padding-bottom: 7px;

  .ui.pointing.secondary.menu {
    border-bottom: none;

    .item {
      padding-left: 19px;
      padding-right: 2px;
      padding-bottom: 8px;
    }

    .active.item {
      color: #3b86ff;
    }

    a.item:active {
      color: #3b86ff;
      border-color: #3b86ff;
    }
  }
}

.ui.header.EmailForm__Header {
  color: $primary;
  font-family: "Lato - Bold";
  font-size: 20px;
  font-weight: 700;
}

.ui.grid.EmailFormBody {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;

  .row.EmailFormBody__Row {
    padding-left: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    .column{
        .ui.list.form{
            .item {
                .BlueCheckBox {
                    text-align: left;
                    margin-top: 20px;
                }
            }
        }
    }

    .EmailFormBody__textArea {
      height: 100%;
    }

    .EmailFormBody__rescheduleButton {
      position: absolute;
      bottom: 50px;
      left: 7px;
    }
  }
}

.EmailForm__Footer {
  padding-bottom: 20px;
  padding-top: 20px;
}
