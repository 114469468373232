@import "~dyl-components/config.scss";

.MappingTable {
    margin-bottom: 5em;
}

.ui.menu .ui.dropdown .menu {
    &>.item.Field__option {
        padding-left: 2em !important;
    }

    &>.item.FieldGroupClassification {
        background-color: $grey-5 !important;
        color: $black !important;
        opacity: 1;
        font-weight: bold !important;
    }

    &>.item.FieldCategory {
        padding-left: 2em !important;
    }

    &>.item.FieldCategory__option {
        padding-left: 3em !important;
    }
}

.ui.basic.button.labeled.dropdown.DataMapping__DefaultValue.icon{
    width: 100%;
    color: $grey-3;
    padding-left: 2.6em !important;
    .DataMapping__DefaultValue{
        background-color: transparent;
    }
}

.ui.icon.DataMapping__DefaultValue__Picklist.button:not(.animated):not(.compact){
    padding: .67857143em 1em;
    padding-left: 2.6em !important;
}

.ui.basic.button.labeled.multiple.scrolling.dropdown.DataMapping__DefaultValue__Picklist.icon{
    width: 100%;
    color: $grey-3;
    padding-left: 2.7em !important;
    .DataMapping__DefaultValue{
        background-color: transparent;
    }
    .text{
        margin: 0; 
    }
}